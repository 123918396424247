import React, { useState, useEffect, useRef } from "react";
import { Container, Row, Col, Form, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-hot-toast";
// import Footer from "./Footer";
// import { Country, State } from "country-state-city";
import Select, { components } from "react-select";
import { BallTriangle } from "react-loader-spinner";
import { fetchUserData } from "../../redux/slice/userData";
import { useDispatch } from "react-redux";
import CryptoJS from "crypto-js";
import JSEncrypt from "jsencrypt";

const ProfileSettings = () => {
  const emojiRegex =
    /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;

  let token;
  let user = JSON.parse(window.localStorage.getItem("login"));
  let profileid;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = JSON.parse(window.localStorage.getItem("login"));
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [countries, setCountries] = useState();
  const [province, setProvince] = useState();
  const [city, setCity] = useState();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedProvince, setSelectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [new_password, setNew_password] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordType, setPasswordType] = useState("password");
  const [confirmpasswordType, setCnfirmpasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [notificationpurpose, setNotificationpurpose] = useState("");
  const [notificationmessage, setNotificationmessage] = useState("");
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [confirmButton, setConfirmButton] = useState(false);
  const [profile_picture, setprofile_picture] = useState("");
  const [membership_type, setMembership_type] = useState("");
  const [isdisabled, setIsDisabled] = useState(true);
  const [isPhoneValid, setIsPhoneValid] = useState("");
  const [iscitydisabled, setIsCityDisabled] = useState(true);
  const dropdownRef = useRef(null);
  const addressContactRef = useRef(null);
  const emailRef = useRef(null);
  const phoneRef = useRef(null);
  const [formData, setFormData] = useState({
    username: "",
    organization: "",
    fullname: "",
    email: "",
    website: "",
    address: "",
    address_contact: "",
    city: "",
    postal_code: "",
    phone_code: "",
    phone: "",
    fax: "",
    company_description: "",
    country: "",
    province: "",
    industry_preferences: "",
    gender: "",
    profile_picture: "",
  });

  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
  });

  const [passworderrors, setPasswordErrors] = useState({
    password: "",
    confirmPassword: "",
  });

  const [errors, setErrors] = useState({
    username: "",
    organization: "",
    fullname: "",
    email: "",
    website: "",
    address: "",
    province: "",
    address_contact: "",
    city: "",
    postal_code: "",
    phone_code: "",
    phone: "",
    fax: "",
    company_description: "",
    industry_preferences: "",
  });
  let dase = [];
  let newErrors = { ...errors };
  let newPasswordErrors = { ...passworderrors };
  if (userData) {
    token = userData?.token;
  } else {
    token = null;
  }

  var id;
  if (userData?._id) {
    id = userData?._id;
  }

  const [selectedValues, setSelectedValues] = useState({
    address: "",
    addressContact: "",
    email: "",
    phone: "",
  });

  const [showSelects, setShowSelects] = useState({
    address: false,
    addressContact: false,
    email: false,
    phone: false,
  });

  const capitalizeFullName = (str) => {
    return str?.charAt(0)?.toUpperCase() + str?.slice(1);
  };
  useEffect(() => {
    document.title = "PCMA || Profile";
  }, []);

  useEffect(() => {
    redirectToLogin();
  }, []);

  const redirectToLogin = () => {
    token = userData?.token || null;
    if (token === null) {
      navigate("/");
    }
  };

  const checkPhone = async (phone) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/users-check`,
        {
          phone: phone,
        }
      );
      if (response.data.message === "Already Exists") {
        setIsPhoneValid(response.data.message);
      } else {
        setIsPhoneValid("");
      }
    } catch (error) {
      console.error(`Error: ${error}`);
    }
  };

  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedCheckPhone = debounce(checkPhone, 2000);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value?.trimStart();
    const filteredValue = trimmedValue?.replace(emojiRegex, "");
    if (name === "password" || name === "confirmPassword") {
      setPasswordData({ ...passwordData, [name]: filteredValue });
      passwordhandal(name, filteredValue);
    } else {
      setFormData({ ...formData, [name]: filteredValue });
      validateField(name, filteredValue);
    }
    switch (name) {
      case "phone":
        debouncedCheckPhone(filteredValue);
        break;
      default:
        break;
    }
  };

  const passwordhandal = (name, value) => {
    if (name === "password") {
      passwordData.password = value;

      newPasswordErrors.password = !value?.trim()
        ? "Password is required."
        : !/^(?=.*[A-Z])(?=.*\d)(?=.*[@$_#^~!%*?&])[A-Za-z\d@$!%_#^~*?&]{8,20}$/.test(
            value
          )
        ? "Password must be 8 to 20 characters and one Number, one Special character and one Capital letter."
        : "";
      if (passwordData.confirmPassword) {
        newPasswordErrors.confirmPassword =
          value !== passwordData.confirmPassword
            ? "Passwords do not match."
            : "";
      }
    }

    if (name === "confirmPassword") {
      passwordData.confirmPassword = value;

      newPasswordErrors.confirmPassword = !value.trim()
        ? "Confirm password is required."
        : value !== passwordData.password
        ? "Passwords do not match."
        : "";
    }

    setPasswordErrors(newPasswordErrors);
  };

  const handleChangeWebsiteFix = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value?.trimStart();
    const filteredValue = trimmedValue?.replace(emojiRegex, "");
    setFormData({ ...formData, [name]: filteredValue });
    websiteUrl(name, filteredValue);
  };

  const websiteUrl = (name, value) => {
    if (name === "website") {
      let pattern =
        /^(http:\/\/www.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
      if (value?.trim() === "") {
        newErrors.website = "";
        newErrors.website = "";
      } else if (!pattern.test(value)) {
        newErrors.website =
          "Please enter a valid URL! Example: http://www.example.com or www.example.com";
      } else {
        newErrors.website = "";
      }
    } else if (name === "fax") {
      let pattern = /^[0-9+]+$/;
      if (value?.trim() === "") {
        newErrors.fax = "";
      } else if (value?.length < 10) {
        newErrors.fax = "Minimum 10 digits";
      } else if (!pattern.test(value)) {
        newErrors.fax = "Use only numbers or plus";
      } else {
        newErrors.fax = "";
      }
    }
    setErrors(newErrors);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[\w.-]+@([\w-]+\.)+[a-zA-Z]{2,7}$/;
    return emailRegex.test(email);
  };

  let validateField = (fieldName, value) => {
    if (fieldName === "fullname") {
      newErrors.fullname = value?.trim() === "" ? "Full name is required." : "";
    } else if (fieldName === "email") {
      newErrors.email =
        value?.trim() === ""
          ? "Email address is required."
          : !isValidEmail(value)
          ? "Invalid email address."
          : "";
    } else if (fieldName === "address") {
      newErrors.address = value?.trim() === "" ? "Address is required." : "";
    } else if (fieldName === "company_description") {
      newErrors.company_description =
        value?.trim() === "" ? "Company description is required." : "";
    } else if (fieldName === "industry_preferences") {
      if (selectedCheckboxes?.length == 0 && dase?.length >= 1) {
        newErrors.industry_preferences = "";
      } else if (dase?.length === 0 && selectedCheckboxes?.length < 1) {
        newErrors.industry_preferences = "The Industry preference is required.";
      } else if (fieldName === "postal_code") {
      let pattern = /^(?=.*[0-9])[0-9a-zA-Z\s-]{3,10}(?<!-)$/;
      if (value && !pattern.test(value)) {
        newErrors.postal_code = "Invalid  postal code";
      } else {
        newErrors.postal_code = "";
      }
    }
    } else if (fieldName === "country") {
      newErrors.country = value?.length === "" ? "Country is required." : "";
    } else if (fieldName === "province") {
      newErrors.province = value?.length === "" ? "Province is required." : "";
    } else if (fieldName === "city") {
      newErrors.city = value?.length === "" ? "City is required." : "";
    } else if (fieldName === "phone") {
      if (value === "") {
        newErrors.phone = "Phone number is required.";
      } else if (value?.length < 14) {
        newErrors.phone = "Invalid Phone number.";
      } else {
        newErrors.phone = "";
      }
    }

    // else if (fieldName === "phone") {
    //   if (value === "") {
    //     newErrors.phone = "Phone number is required.";
    //   } else if (value?.length < 14) {
    //     newErrors.phone = "Phone number minimum 10 digit ";
    //   } else {
    //     newErrors.phone = "";
    //   }
    // }
    setErrors(newErrors);
  };

  const provinceValidateField = (fieldName, value) => {
    if (fieldName === "province") {
      newErrors.province = value === "" ? "Province is required." : "";
      setErrors(newErrors);
    }
    setErrors(newErrors);
  };

  const cityValidateField = (fieldName, value) => {
    if (fieldName === "city") {
      newErrors.city = value === "" ? "City is required." : "";
      setErrors(newErrors);
    }
    setErrors(newErrors);
  };

  const countryValidateField = (country, value) => {
    if (country === "country") {
      newErrors.country = value === "" ? "Country is required." : "";
      setErrors(newErrors);
    }
    setErrors(newErrors);
  };

  const handleKeyPresss = (e) => {
    if (emojiRegex.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleClose = () => setShowModal(false);
  profileid = user?.user_data?._id;
  const reDirect = () => {
    if (!profileid || profileid === "undefined") {
      navigate("/firm");
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        addressContactRef.current &&
        !addressContactRef.current.contains(event.target) &&
        emailRef.current &&
        !emailRef.current.contains(event.target) &&
        phoneRef.current &&
        !phoneRef.current.contains(event.target)
      ) {
        setShowSelects({
          address: false,
          addressContact: false,
          email: false,
          phone: false,
        });
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    reDirect();
  }, [profileid]);

  const getallData = () => {
    setIsLoading(true);
    if (profileid !== undefined) {
      axios
        .get(`${process.env.REACT_APP_PCMA_BACKEND}/api/profile-settings`, {
          params: {
            _id: profileid,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          if (response?.data?.message === "Unauthozied User") {
            localStorage.removeItem("login");
            navigate("/");
            toast.error("Unauthozied user !");
          }
          const profiledata = response?.data;
          // dispatch(fetchUserData({ localUserId: user._id, token: user.token }));
          setFormData((prevData) => ({
            ...prevData,
            username: profiledata?.username,
            organization: profiledata?.organization,
            fullname: profiledata?.name,
            email: profiledata?.email,
            website: profiledata?.website,
            address: profiledata?.address,
            address_contact: profiledata?.address_cont,
            postal_code: profiledata?.postal_code,
            phone_code: profiledata?.phone_code,
            phone: profiledata?.phone,
            fax: profiledata?.fax,
            company_description: profiledata?.company_description,
            status: profiledata?.status,
            image: profiledata?.profile_picture,
            gender: profiledata?.gender,
          }));

          setprofile_picture(profiledata?.profile_picture);
          setSelectedCountry({
            value: profiledata?.country,
            label: profiledata?.country,
          });
          setSelectedProvince({
            value: profiledata?.province,
            label: profiledata?.province,
          });
          setSelectedCity({
            value: profiledata?.city,
            label: profiledata.city,
          });
          // setPhoneOption(profiledata?.phone_code);
          setSelectedCheckboxes(profiledata?.industry_preferences);
          if (Array.isArray(profiledata?.membership_type)) {
            setMembership_type(
              profiledata?.membership_type[0].subscription_name
            );
          } else {
            setMembership_type(profiledata?.membership_type);
          }

          // setSelectedValues(profiledata?.private_fields);
          const updatedValues = {};
          if (profiledata?.private_fields.includes("email")) {
            updatedValues.email = "email";
          }

          if (profiledata?.private_fields.includes("phone")) {
            updatedValues.phone = "phone";
          }

          if (profiledata?.private_fields.includes("addressContact")) {
            updatedValues.addressContact = "addressContact";
          }

          if (profiledata?.private_fields.includes("address")) {
            updatedValues.address = "address";
          }

          setSelectedValues((prevValues) => ({
            ...prevValues,
            ...updatedValues,
          }));

          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          console.error(`Error: ${error}`);
          if (error?.message === "Request failed with status code 401") {
            localStorage.removeItem("login");
            navigate("/");
          }
        });
    }
  };
  useEffect(() => {
    getallData();
  }, []);

  const fetchNoticDataGet = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/all-notice-data-user`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.message === "Unauthozied user") {
        localStorage.removeItem("login");
        navigate("/");
        toast.error(`Unauthozied user !`);
      }
      // setNoticeData(response?.data?.notice_data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          {props.selectProps.menuIsOpen ? (
            <i class="fa-solid fa-angle-up"></i>
          ) : (
            <i className="fa-solid fa-angle-down"></i>
          )}
        </components.DropdownIndicator>
      )
    );
  };

  useEffect(() => {
    fetchNoticDataGet();
  }, []);

  function phoneCodeFormat(input) {
    input = input?.replace(/\D/g, "");
    input = input?.length > 0 && !input.startsWith("+") ? "+" + input : input;
    return input;
  }

  function phoneFormat(input) {
    input = input?.replace(/\D/g, "");
    input = input?.trim();
    input = input?.substring(0, 10);
    var size = input?.length;
    if (size === 0) {
      input = input;
    } else if (size < 4 && size > 1) {
      input = "(" + input;
    } else if (size < 7 && size > 1) {
      input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
    } else if (size <= 11 && size > 1) {
      input =
        "(" +
        input.substring(0, 3) +
        ") " +
        input.substring(3, 6) +
        "-" +
        input.substring(6, 11);
    }
    return input;
  }

  const handleCheckboxChangeAll = (event) => {
    if (event.target.checked === true) {
      setSelectedCheckboxes([
        "Private Placements Mining",
        "Private Placements Oil and Gas",
        "Private Placements - Other",
        "Portfolio Management with Exempt Clients",
        "Mergers and Acquisitions",
        "Private Placements - Real Estate",
        "Distributor of Exempt Investments",
        "Other",
      ]);
      errors.industry_preferences = "";
    } else {
      setSelectedCheckboxes([]);
      errors.industry_preferences = "Industry preference is required.";
    }
  };

  const handleCheckboxChange = (event) => {
    const { value } = event.target;
    dase = selectedCheckboxes?.includes(value)
      ? selectedCheckboxes.filter((checkbox) => checkbox !== value)
      : [...selectedCheckboxes, value];
    validateField("industry_preferences", value);
    setSelectedCheckboxes(dase);
  };

  const handlepwd = () => {
    setShow((prevShow) => !prevShow);
    setPasswordErrors({})
    setPasswordData({
      password: "",
      confirmPassword: "",
    });
  };
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };
  const toggleconfirmPassword = () => {
    if (confirmpasswordType === "password") {
      setCnfirmpasswordType("text");
      return;
    }
    setCnfirmpasswordType("password");
  };

  const validatePasswordForm = () => {
    for (const fieldName in passwordData) {
      if (passwordData.hasOwnProperty(fieldName)) {
        passwordhandal(fieldName, passwordData[fieldName]);
      }
    }
    setIsLoading(false);
    setConfirmButton(false);
  };

  const handleNewPasword = async (e) => {
    e.preventDefault();
    validatePasswordForm();
    if (!Object.values(newPasswordErrors).some((error) => error !== "")) {
      try {
        const user = JSON.parse(window.localStorage.getItem("login"));
        setConfirmButton(true);

        let password = {
          new_password: passwordData?.password,
        };
        const publicKey = import.meta.env.VITE_REACT_APP_PUBLIC_KEY;
        const encryptor = new JSEncrypt();
        encryptor.setPublicKey(publicKey);
        const windowTime = Date.now();
        let stringify = {
          ...password,
          windowTime: btoa(windowTime),
        };
        var encrypted = encryptor.encrypt(JSON.stringify(stringify));
        const { data } = await axios.put(
          `${process.env.REACT_APP_PCMA_BACKEND}/api/profile-update/${user?._id}`,
          {
            encrypted,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setConfirmButton(true);
        if (data.message === "Password updated successfully") {
          toast.success(data?.message);
          setTimeout(() => {
            setConfirmButton(false);
          }, 5000);
          setShow(false);
          setPasswordData({
            password: "",
            confirmPassword: "",
          });
        }
      } catch (error) {
        console.error(`Error: ${error}`);
        setConfirmButton(false);
      }
    }
  };

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_PCMA_BACKEND}/api/country`, {
        headers: {
          "X-Find": "Canada",
        },
      })
      .then((res) => {
        const options = res?.data?.map((province) => ({
          value: province._id,
          label: province.name,
          phoneCode: province.phone_code,
        }));
        setCountries(options);
      })
      .catch((error) => {
        console.error(`Error: ${error}`);
      });
  }, []);

  const provinceApi = (value) => {
    axios
      .get(`${process.env.REACT_APP_PCMA_BACKEND}/api/state`, {
        headers: {
          "X-Id": value.value,
        },
      })
      .then((res) => {
        const options1 = res?.data?.map((name) => ({
          value: name._id,
          label: name.name,
        }));
        setProvince(options1);
      })
      .catch((error) => {
        console.error(`Error: ${error}`);
      });
  };

  const CityApi = (value) => {
    axios
      .get(`${process.env.REACT_APP_PCMA_BACKEND}/api/city`, {
        headers: {
          "X-Id": value.value,
        },
      })
      .then((res) => {
        const options1 = res?.data?.map((name) => ({
          value: name._id,
          label: name.name,
        }));
        setCity(options1);
      })
      .catch((error) => {
        console.error(`Error: ${error}`);
      });
  };

  const handleCountryChange = (selectedOption) => {
    provinceApi(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      phone_code: String(selectedOption?.phoneCode),
    }));
    setSelectedCountry(selectedOption);

    setSelectedProvince(null);
    setSelectedCity(null);
    countryValidateField("country", selectedOption.label);
    provinceValidateField("province", "");
    // cityValidateField("city", "");
    setIsDisabled(false);
    setIsCityDisabled(true);
  };

  const handleStateChange = (selectedOption) => {
    CityApi(selectedOption);
    setSelectedProvince(selectedOption);
    setSelectedCity(null);
    setIsCityDisabled(false);
    provinceValidateField("province", selectedOption?.label);
    cityValidateField("city", "");
  };

  const handleCityChange = (selectedOption) => {
    setSelectedCity(selectedOption);
    cityValidateField("city", selectedOption?.label);
  };

  const handleKeyPress = (e) => {
    const char = String.fromCharCode(e.keyCode || e.which);
    if (!/^[0-9+]+$/.test(char)) {
      e.preventDefault();
    }
  };

  const handleIconClick = (fieldName) => {
    setShowSelects((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const handlePaste = (e, fieldName) => {
    const pastedData = e.clipboardData.getData("text");

    if (pastedData) {
      const isValid = validateField(fieldName, pastedData);
      if (isValid) {
        setFormData((prevState) => ({
          ...prevState,
          [fieldName]: pastedData,
        }));
      }
    }
  };

  const handleChanges = (fieldName, event) => {
    setSelectedValues((prevState) => ({
      ...prevState,
      [fieldName]: event.target.value,
    }));

    setShowSelects((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  const validateForm = () => {
    for (const fieldName in formData) {
      if (formData.hasOwnProperty(fieldName)) {
        validateField(fieldName, formData[fieldName]);
      }
      if (selectedCountry === null) {
        newErrors.country = "Country is required.";
        setErrors(newErrors);
      }
      if (selectedProvince === null) {
        newErrors.province = "Province is required.";
        setErrors(newErrors);
      }
      if (selectedCity === null) {
        newErrors.city = "City is required.";
        setErrors(newErrors);
      }
    }
  };

  const privateFields = [];

  const handleSubmit = async (e) => {
    e.preventDefault();
    validateForm();

    if (selectedValues) {
      if (selectedValues?.phone && selectedValues.phone?.length > 0) {
        privateFields.push(selectedValues?.phone);
      }
      if (selectedValues?.address && selectedValues.address?.length > 0) {
        privateFields.push(selectedValues?.address);
      }
      if (
        selectedValues?.addressContact &&
        selectedValues.addressContact?.length > 0
      ) {
        privateFields.push(selectedValues?.addressContact);
      }
      if (selectedValues?.email && selectedValues.email?.length > 0) {
        privateFields.push(selectedValues?.email);
      }
    }

    if (
      !Object.values(errors).some((error) => error !== "") &&
      isPhoneValid === ""
    ) {
      try {
        setButtonDisabled(true);
        const user = JSON.parse(window.localStorage.getItem("login"));
        const { data } = await axios.put(
          `${process.env.REACT_APP_PCMA_BACKEND}/api/profile-update/${user._id}`,
          {
            name: formData?.fullname,
            email: formData?.email,
            organization: formData?.organization,
            website: formData?.website,
            address: formData?.address,
            address_cont: formData?.address_contact,
            city: selectedCity?.label,
            country: selectedCountry?.label,
            province: selectedProvince?.label,
            postal_code: formData?.postal_code,
            phone: formData?.phone,
            phone_code: formData?.phone_code,
            fax: formData?.fax,
            company_description: formData?.company_description,
            membership_type: membership_type,
            industry_preferences: selectedCheckboxes,
            gender: formData?.gender,
            private_fields: privateFields,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch(fetchUserData({ localUserId: user._id, token: user.token }));
        // setButtonDisabled(true);
        if (data?.message === "Profile updated successfully") {
          toast.success("Profile updated successfully");
          setTimeout(() => {
            setButtonDisabled(false);
          }, 5000);
          setShow(false);
          setPasswordData({
            password: "",
            confirmPassword: "",
          });
        } else {
          toast.error(data.message);
        }
        getallData();
      } catch (error) {
        console.error(error);
        if (error?.message === "Request failed with status code 400") {
          setTimeout(() => {
            setButtonDisabled(false);
          }, 5000);
          setShow(false);
          setPasswordData({
            password: "",
            confirmPassword: "",
          });
        } else {
          setButtonDisabled(false);
        }
      }
    } else {
      toast.error(`Fill all the required field `);
        setShow(false);
        setPasswordData({
          password: "",
          confirmPassword: "",
        });
    }
  };

  const handleFile = async (event) => {
    const fileInput = document.getElementById("fileInput");
    const file = event.target.files[0];
    const maxSizeInBytes = 2 * 1024 * 1024; // 2MB
    const validFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (!file) {
      toast.error("No file selected");
      return;
    } else if (!validFileTypes.includes(file.type)) {
      toast.error(
        "Invalid file type. Only JPG, JPEG, and PNG files are allowed"
      );
      fileInput.value = "";
      return;
    } else if (file.size > maxSizeInBytes) {
      toast.error("File size should be under 2MB");
      fileInput.value = "";
      return;
    } else {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("image", file);
      validateForm();
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PCMA_BACKEND}/api/profile-picture-upload?_id=${id}`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.message === "success") {
          toast.success("Profile picture updated successfully");
        } else {
          toast.error(response.data.message);
        }

        if (response.status !== 200) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred while uploading the file");
      } finally {
        setIsLoading(false);
        getallData();
        fileInput.value = "";
      }
    }
  };

  const handleDeleteClick = async () => {
    if (profile_picture) {
      try {
        const response = await axios.post(
          `${process.env.REACT_APP_PCMA_BACKEND}/api/profile-picture-upload?_id=${id}`,
          { remove_image_request: true },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        if (response) {
          getallData();
          toast.success("Profile picture removed successfully");
        }
      } catch (error) {
        console.log("error:", error);
      }
    } else {
      toast.error("No profile image");
    }
  };

  const handleKeyPress2 = (e) => {
    const char = String.fromCharCode(e.keyCode || e.which);
    if (!/^[a-zA-Z\s.]+$/.test(char)) {
      e.preventDefault();
    }
  };

  return (
    <>
      {isLoading ? (
        <Container fluid className="loader">
          <Row
            className="g-2 pb-3 d-flex justify-content-center align-content-center "
            style={{ height: "79vh" }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-content-center"
            >
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <div className="container-fluid px-sm-3 mt-5 mt-lg-4">
            <div className="row g-2">
              <div className="col-12">
                <div className="j_account_information d-flex align-items-center px-4">
                  General Information
                </div>
              </div>
              <div className="col-12">
                <div className="j_profile_setting_container px-4">
                  <Form className="row ">
                    <div className="col-sm-12 col-md-2 col-xl-3">
                      <div className=" d-flex align-items-center justify-content-center justify-content-md-start mt-3 mb-md-4">
                        <div className="profile_box d-flex justify-content-center align-items-center flex-column">
                          {profile_picture?.length > 0 ? (
                            <img
                              className="profile_picture img-fluid"
                              src={`${profile_picture}`}
                              alt="logo "
                            />
                          ) : (
                            <img
                              className="profile_picture img-fluid ms-2"
                              src="./asset/users.png"
                              alt="logo "
                            />
                          )}

                          <Form className="mt-3">
                            <Form.Group
                              controlId="formFile"
                              className="mb-md-3"
                            >
                              <Form.Control
                                type="file"
                                required
                                onChange={handleFile}
                                style={{ display: "none" }}
                                id="fileInput"
                              />
                              <div className="d-flex flex-wrap flex-column">
                                <Button
                                  style={{
                                    background: "#fff",
                                    color: "#137CD9",
                                    border: "2px solid rgba(0, 0, 0, 0.10)",
                                    opacity: "0.6",
                                    padding: "3px 15px",
                                  }}
                                  onClick={() =>
                                    document.getElementById("fileInput").click()
                                  }
                                >
                                  Edit
                                </Button>{" "}
                                <Button
                                  style={{
                                    background: "#fff",
                                    color: "#1E1E1E",
                                    border: "2px solid rgba(0, 0, 0, 0.10)",
                                    opacity: "0.6",
                                    padding: "3px 15px",
                                    marginTop: "4px",
                                  }}
                                  onClick={handleDeleteClick}
                                  disabled={!profile_picture?.length}
                                >
                                  Remove
                                </Button>
                              </div>
                            </Form.Group>
                          </Form>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-5 col-xl-4">
                      <Form.Group className="Name   mt-md-3 py-2">
                        <Form.Label className="form_label position-relative">
                          Full Name
                          <svg
                            className="ms-1 position-absolute"
                            style={{ top: "2px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </Form.Label>
                        <div className="input_field">
                          <Form.Control
                            className={`form-control
                                     ${
                                       ((errors.fullname &&
                                         formData.fullname === "") ||
                                         errors.fullname) &&
                                       "error_active"
                                     }
                                     ${
                                       errors.fullname === "" &&
                                       formData.fullname &&
                                       "sucess_active"
                                     }`}
                            value={capitalizeFullName(formData?.fullname)}
                            type="text"
                            name="fullname"
                            autoComplete="off"
                            onKeyPress={handleKeyPress2}
                            placeholder="Enter Full Name"
                            onChange={handleChange}
                            maxLength={68}
                            onPaste={handlePaste}
                          />
                          <span style={{ color: "red" }}>
                            {errors.fullname}
                          </span>
                        </div>
                      </Form.Group>
                      <Form.Group className="Name mt-md-3 py-2 ">
                        <Form.Label className="form_label">Gender</Form.Label>
                        <div className="  flex-wrap flex-sm-nowrap ">
                          <div className="form-check form-check-inline ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio1"
                              value="male"
                              onChange={handleChange}
                              checked={formData?.gender === "male"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio1"
                            >
                              Male
                            </label>
                          </div>
                          <div className="form-check form-check-inline ">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio2"
                              value="female"
                              onChange={handleChange}
                              checked={formData?.gender === "female"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio2"
                            >
                              Female
                            </label>
                          </div>
                          <div className="form-check form-check-inline">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="gender"
                              id="inlineRadio3"
                              value="others"
                              onChange={handleChange}
                              checked={formData?.gender === "others"}
                            />
                            <label
                              className="form-check-label"
                              htmlFor="inlineRadio3"
                            >
                              Others
                            </label>
                          </div>
                        </div>
                      </Form.Group>
                    </div>
                    <div className="col-2 col-xl-1 d-none d-xl-block"></div>
                    <div className="col-sm-12 col-md-5 col-xl-4">
                      <Form.Group className="Name   mt-md-3  py-2">
                        <Form.Label className="form_label position-relative">
                          Membership Type
                          <svg
                            className="ms-1 position-absolute"
                            style={{ top: "2px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="none"
                          >
                            <path
                              d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                              fill="#F92828"
                            />
                          </svg>
                        </Form.Label>
                        <div className="input_field">
                          <Form.Control
                            type="text"
                            name="full_name"
                            disabled
                            value={membership_type}
                            onChange={(e) => setMembership_type(e.target.value)}
                          />
                          <Form.Control.Feedback type="invalid-feedback text-start p-0 ps-sm-2"></Form.Control.Feedback>
                        </div>
                      </Form.Group>
                    </div>
                  </Form>
                </div>
              </div>

              <div className="col-12">
                <div className="j_account_information d-flex align-items-center px-4">
                  Account Information
                </div>
                <div className="j_profile_setting_container">
                  <div className="row">
                    <div className="col-xl-12">
                      <Form>
                        <div
                          className=" row j_account_informations"
                          style={{ padding: "2rem 0rem" }}
                        >
                          <div className="col-md-6">
                            <div className="row align-items-center">
                              <div className="col-sm-4">
                                <Form.Label className=" mb-0">
                                  Username
                                  <svg
                                    className="ms-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="9"
                                    height="9"
                                    viewBox="0 0 9 9"
                                    fill="none"
                                  >
                                    <path
                                      d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                      fill="#F92828"
                                    />
                                  </svg>
                                </Form.Label>
                              </div>
                              <div className="col-sm-8">
                                <Form.Group className="username py-2">
                                  <div className="input_field">
                                    <Form.Control
                                      type="text"
                                      name="username"
                                      disabled
                                      value={formData?.username || ""}
                                      onPaste={handlePaste}
                                      onChange={handleChange}
                                    />
                                    <Form.Control.Feedback
                                      className="invalid-feedback text-start p-0 ps-sm-2"
                                      type="invalid"
                                    ></Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="row align-items-center">
                              <div className="col-sm-4">
                                <Form.Label className=" mb-0">
                                  Email Address
                                </Form.Label>
                              </div>
                              <div className="col-sm-8">
                                <Form.Group className="email py-2">
                                  <div className="input_field">
                                    <div className="d-flex align-items-center position-relative">
                                      <Form.Control
                                        value={formData?.email || ""}
                                        type="email"
                                        name="email"
                                        disabled
                                        placeholder="Enter Email"
                                        onChange={handleChange}
                                        onPaste={handlePaste}
                                      />

                                      <div
                                        className="toggle_globe position-absolute"
                                        ref={emailRef}
                                      >
                                        <i
                                          className="fa fa-globe position-relative cursor-pointer"
                                          aria-hidden="true"
                                          onClick={() =>
                                            handleIconClick("email")
                                          }
                                        ></i>
                                        {showSelects.email && (
                                          <select
                                            onChange={(e) =>
                                              handleChanges("email", e)
                                            }
                                            value={selectedValues.email}
                                            className="selection"
                                          >
                                            <option value="">
                                              <i
                                                className="fa fa-globe"
                                                aria-hidden="true"
                                              ></i>{" "}
                                              Set As Public
                                            </option>
                                            <option
                                              value="email"
                                              selected={privateFields?.includes(
                                                "email"
                                              )}
                                            >
                                              <i
                                                className="fa fa-lock"
                                                aria-hidden="true"
                                              ></i>{" "}
                                              Set As Private
                                            </option>
                                          </select>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-sm-4"></div>
                                  <div className="col-sm-8">
                                    <div className="verified ms-2 mt-2">
                                      <p>
                                        Verified{" "}
                                        <i className="fa-solid fa-check ms-1"></i>
                                      </p>
                                    </div>
                                  </div>
                                </Form.Group>
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-md-6">
                            <Row className="align-items-center">
                              <Col sm={3}>
                                <Form.Label className=" mb-0">
                                  Email Address
                                  <svg
                                    className="ms-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="9"
                                    height="9"
                                    viewBox="0 0 9 9"
                                    fill="none"
                                  >
                                    <path
                                      d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                      fill="#F92828"
                                    />
                                  </svg>
                                </Form.Label>
                              </Col>
                              <Col sm={9}>
                                <Form.Group className="email  py-2">
                                  <div className="input_field">
                                    <div className="d-flex align-items-center position-relative">
                                      <Form.Control
                                        className={`
                                          form-control registration_field
                                          ${
                                            ((errors.email &&
                                              formData.email === "") ||
                                              isEmailValid ===
                                                "Already Exists" ||
                                              errors.email) &&
                                            "error_active"
                                          }
                                          ${
                                            errors.email === "" &&
                                            formData.email &&
                                            isEmailValid === "" &&
                                            "sucess_active"
                                          }`}
                                        value={formData?.email || ""}
                                        type="email"
                                          name="email"
                                          disabled
                                        placeholder="Enter Email"
                                        onChange={handleChange}
                                        onPaste={handlePaste}
                                      />
                                      <div className="toggle_globe position-absolute">
                                        <i
                                          className="fa fa-globe position-relative cursor-pointer"
                                          aria-hidden="true"
                                          onClick={() =>
                                            handleIconClick("email")
                                          }
                                        ></i>
                                        {showSelects.email && (
                                          <select
                                            onChange={(e) =>
                                              handleChanges("email", e)
                                            }
                                            value={selectedValues.email}
                                            className="selection"
                                          >
                                            <option value="">
                                              <i
                                                className="fa fa-globe"
                                                aria-hidden="true"
                                              ></i>{" "}
                                              Set As Public
                                            </option>
                                            <option
                                              value="email"
                                              selected={privateFields?.includes(
                                                "email"
                                              )}
                                            >
                                              <i
                                                className="fa fa-lock"
                                                aria-hidden="true"
                                              ></i>{" "}
                                              Set As Private
                                            </option>
                                          </select>
                                        )}
                                      </div>
                                      </div>
                                      <div className="verified">
                                        <p>Verified <i className="fa-solid fa-check ms-1"></i></p>
                                      </div>
                                    
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div> */}
                          <div className="col-md-6">
                            <Row className="align-items-center">
                              <Col sm={4}>
                                <Form.Label className="mb-0">
                                  Password{" "}
                                </Form.Label>
                              </Col>
                              <Col sm={8}>
                                <Form.Group className="password   py-2">
                                  <div className="input_field">
                                    <Form.Control
                                      type="password"
                                      name="password"
                                      disabled
                                      placeholder="***********"
                                    />
                                    <Form.Control.Feedback type="invalid-feedback text-start p-0 ps-sm-2"></Form.Control.Feedback>
                                  </div>
                                </Form.Group>
                              </Col>
                            </Row>
                          </div>
                          <div className=" col-md-4">
                            <div className="d-sm-flex align-items-center mt-2 mb-3 mb-sm-0">
                              <Button
                                className="confirm_pwd   border-0"
                                type="button"
                                onClick={handlepwd}
                              >
                                {show ? "Reset" : "Change Password"}
                                {/* Change Password */}
                              </Button>
                            </div>
                          </div>

                          {/* {show === true ? ( */}
                            {/* <> */}
                              {/* <div className="col-md-7 col-lg-6">
                                <div className="row align-items-center">
                                  <Col sm={4}>
                                    <Form.Label className="mb-0">
                                      New Password{" "}
                                      <svg
                                        className="ms-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="9"
                                        height="9"
                                        viewBox="0 0 9 9"
                                        fill="none"
                                      >
                                        <path
                                          d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                          fill="#F92828"
                                        />
                                      </svg>
                                    </Form.Label>
                                  </Col>
                                  <Col sm={8}>
                                    <Form.Group className="password   py-2">
                                      <div className="input_field position-relative">
                                        <Form.Control
                                          type={passwordType}
                                          className={`
                                                    ${
                                                      ((passworderrors.password &&
                                                        passwordData.password ===
                                                          "") ||
                                                        passworderrors.password) &&
                                                      "error_active"
                                                    }
                                                    ${
                                                      passworderrors.password ===
                                                        "" &&
                                                      passwordData.password &&
                                                      "sucess_active"
                                                    }`}
                                          name="password"
                                          value={passwordData?.password}
                                          placeholder="Enter New Password "
                                          onChange={handleChange}
                                        />
                                        <button
                                          type="button"
                                          className="bg-transparent border-0 position-absolute eyBtn"
                                          onClick={togglePassword}
                                        >
                                          {passwordType === "password" ? (
                                            <i className="fa-regular fa-eye-slash"></i>
                                          ) : (
                                            <i className="fa-regular fa-eye"></i>
                                          )}
                                        </button>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col sm={4}></Col>
                                  <Col sm={8}>
                                    <span style={{ color: "red" }}>
                                      {newPasswordErrors?.password}
                                    </span>
                                  </Col>
                                </div>
                                <div className="row align-items-center">
                                  <Col sm={4}>
                                    <Form.Label className="mb-0">
                                      Re-Type Password
                                      <svg
                                        className="ms-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="9"
                                        height="9"
                                        viewBox="0 0 9 9"
                                        fill="none"
                                      >
                                        <path
                                          d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                          fill="#F92828"
                                        />
                                      </svg>
                                    </Form.Label>
                                  </Col>
                                  <Col sm={8}>
                                    <Form.Group className="password   py-2">
                                      <div className="input_field position-relative">
                                        <Form.Control
                                          type={confirmpasswordType}
                                          name="confirmPassword"
                                          className={`form-control
                                            ${
                                              ((passworderrors.confirmPassword &&
                                                passwordData.confirmPassword ===
                                                  "") ||
                                                passworderrors.confirmPassword) &&
                                              "error_active"
                                            }
                                            ${
                                              passworderrors.confirmPassword ===
                                                "" &&
                                              passwordData.confirmPassword &&
                                              "sucess_active"
                                            }`}
                                          value={passwordData?.confirmPassword}
                                          placeholder="Enter Re-Type Password"
                                          onChange={handleChange}
                                        />
                                        <button
                                          type="button"
                                          className="bg-transparent border-0 position-absolute eyBtn"
                                          onClick={toggleconfirmPassword}
                                        >
                                          {confirmpasswordType ===
                                          "password" ? (
                                            <i className="fa-regular fa-eye-slash"></i>
                                          ) : (
                                            <i className="fa-regular fa-eye"></i>
                                          )}
                                        </button>
                                      </div>
                                    </Form.Group>
                                  </Col>
                                  <Col sm={4}></Col>
                                  <Col sm={8}>
                                    <span style={{ color: "red" }}>
                                      {newPasswordErrors?.confirmPassword}
                                    </span>
                                  </Col>
                                </div>
                              </div>
                              <div className="col-md-5 col-lg-4">
                                <div className="d-sm-flex align-items-center mt-2">
                                  <Button
                                    className="confirm_pwd   border-0"
                                    type="submit"
                                    onClick={handleNewPasword}
                                    disabled={confirmButton}
                                  >
                                    Confirm Password
                                  </Button>
                                </div>
                              </div> */}
                              <Modal show={show} onHide={()=>setShow(false)} centered>
                              <Modal.Header closeButton>
                                <Modal.Title>Change Your Password</Modal.Title>
                              </Modal.Header>
                              <Modal.Body>
                                <Row className="">
                                  <Col sm={5}>
                                    <Form.Label className="mb-0">
                                      New Password{" "}
                                      <svg
                                        className="ms-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="9"
                                        height="9"
                                        viewBox="0 0 9 9"
                                        fill="none"
                                      >
                                        <path
                                          d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                          fill="#F92828"
                                        />
                                      </svg>
                                    </Form.Label>
                                  </Col>
                                  <Col sm={7}>
                                  <Form.Group className="password">
                                      <div className="input_field position-relative">
                                        <Form.Control
                                          type={passwordType}
                                          className={`
                                                    ${
                                                      ((passworderrors.password &&
                                                        passwordData.password ===
                                                          "") ||
                                                        passworderrors.password) &&
                                                      "error_active"
                                                    }
                                                    ${
                                                      passworderrors.password ===
                                                        "" &&
                                                      passwordData.password &&
                                                      "sucess_active"
                                                    }`}
                                          name="password"
                                          value={passwordData?.password}
                                          placeholder="Enter New Password "
                                          onChange={handleChange}
                                        />
                                        <button
                                          type="button"
                                          className="bg-transparent border-0 position-absolute eyBtn"
                                          onClick={togglePassword}
                                        >
                                          {passwordType === "password" ? (
                                            <i className="fa-regular fa-eye-slash"></i>
                                          ) : (
                                            <i className="fa-regular fa-eye"></i>
                                          )}
                                        </button>
                                      </div>
                                      <span style={{ color: "red" }}>
                                      {newPasswordErrors?.password}
                                    </span>
                                    </Form.Group>
                                  </Col>
                                </Row>
                                <Row className="">
                                  <Col sm={5}>
                                    <Form.Label className="mb-0">
                                      Re-Type Password
                                      <svg
                                        className="ms-1"
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="9"
                                        height="9"
                                        viewBox="0 0 9 9"
                                        fill="none"
                                      >
                                        <path
                                          d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                          fill="#F92828"
                                        />
                                      </svg>
                                    </Form.Label>
                                  </Col>
                                  <Col sm={7}>
                                  <Form.Group className="password   py-2">
                                      <div className="input_field position-relative">
                                        <Form.Control
                                          type={confirmpasswordType}
                                          name="confirmPassword"
                                          className={`form-control
                                            ${
                                              ((passworderrors.confirmPassword &&
                                                passwordData.confirmPassword ===
                                                  "") ||
                                                passworderrors.confirmPassword) &&
                                              "error_active"
                                            }
                                            ${
                                              passworderrors.confirmPassword ===
                                                "" &&
                                              passwordData.confirmPassword &&
                                              "sucess_active"
                                            }`}
                                          value={passwordData?.confirmPassword}
                                          placeholder="Enter Re-Type Password"
                                          onChange={handleChange}
                                        />
                                        <button
                                          type="button"
                                          className="bg-transparent border-0 position-absolute eyBtn"
                                          onClick={toggleconfirmPassword}
                                        >
                                          {confirmpasswordType ===
                                          "password" ? (
                                            <i className="fa-regular fa-eye-slash"></i>
                                          ) : (
                                            <i className="fa-regular fa-eye"></i>
                                          )}
                                        </button>
                                      </div>
                                      <span style={{ color: "red" }}>
                                      {newPasswordErrors?.confirmPassword}
                                    </span>
                                    </Form.Group>
                                  </Col>
                                </Row>
                              </Modal.Body>
                              <Modal.Footer>
                                <button className="user_form_cancal" onClick={()=>setShow(false)}>
                                  Close
                                </button>
                                <Button
                                    className="confirm_pwd   border-0"
                                    type="submit"
                                    onClick={handleNewPasword}
                                    disabled={confirmButton}
                                  >
                                    Confirm Password
                                  </Button>
                              </Modal.Footer>
                            </Modal>
                              {/* <div className="col-md-7 col-lg-6">
                                <Row className="align-items-center">
                                  
                                </Row>
                              </div>
                              <div className="col-md-5 col-lg-6"></div>
                              <div className="col-md-7 col-lg-6">
                                <Row className="align-items-center">
                                  
                                </Row>
                              </div>
                                
                               */}
                            {/* </> */}
                          {/* ) : ( */}
                            {/* " " */}
                           {/* )} */}
                        </div>
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="j_account_information d-flex align-items-center px-4">
                  Professional Information
                </div>
                <div className="j_profile_setting_container">
                  <Form
                    className="row j_account_informations"
                    style={{ padding: "2rem 0rem" }}
                  >
                    {/*Organization*/}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0">
                            Organization{" "}
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Group className="organization  py-2  ">
                            <div className="input_field">
                              <Form.Control
                                type="text"
                                name="organization"
                                disabled
                                value={formData?.organization || ""}
                                onPaste={handlePaste}
                                onChange={handleChange}
                              />
                              <Form.Control.Feedback type="invalid-feedback text-start p-0 ps-sm-2"></Form.Control.Feedback>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    {/*Website*/}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0">Website </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Group className="websited  py-2  ">
                            <div className="input_field">
                              <Form.Control
                                className={`
                                      form-control
                                      ${
                                        ((errors.website &&
                                          formData.website === "") ||
                                          errors.website) &&
                                        "error_active"
                                      }
                                      ${
                                        errors.website === "" &&
                                        formData.website &&
                                        "sucess_active"
                                      }`}
                                type="text"
                                name="website"
                                value={formData?.website || ""}
                                onChange={handleChangeWebsiteFix}
                                placeholder="Enter Website"
                                onPaste={handlePaste}
                                onKeyPress={handleKeyPresss}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={8}>
                          <span
                            className="mt-2"
                            style={{
                              color: "red",
                              lineHeight: " 19px",
                              display: "inline-block",
                            }}
                          >
                            {errors.website}
                          </span>
                        </Col>
                      </Row>
                    </div>
                    {/* Address*/}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0">
                            Address
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Group className="address py-2 ">
                            <div className="input_field">
                              <div className="d-flex align-items-center position-relative">
                                <Form.Control
                                  className={`
                           form-control registration_field
                           ${
                             errors.address &&
                             formData.address === "" &&
                             "error_active"
                           }
                           ${
                             errors.address === "" &&
                             formData.address &&
                             "sucess_active"
                           }`}
                                  type="text"
                                  name="address"
                                  value={formData?.address || ""}
                                  onChange={handleChange}
                                  placeholder="Enter Address"
                                  maxLength={55}
                                  onPaste={handlePaste}
                                  // onChange={handleChanges}
                                />
                                <div
                                  className="toggle_globe position-absolute"
                                  ref={dropdownRef}
                                >
                                  <i
                                    className="fa fa-globe position-relative"
                                    aria-hidden="true"
                                    onClick={() => handleIconClick("address")}
                                  ></i>
                                  {showSelects.address && (
                                    <select
                                      onChange={(e) =>
                                        handleChanges("address", e)
                                      }
                                      value={selectedValues.address}
                                      className="selection"
                                    >
                                      <option value="">
                                        <i
                                          className="fa fa-globe"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Set As Public
                                      </option>
                                      <option value="address">
                                        <i
                                          className="fa fa-lock"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Set As Private
                                      </option>
                                    </select>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={8}>
                          <span style={{ color: "red" }}>
                            {errors?.address}
                          </span>
                        </Col>
                      </Row>
                    </div>
                    {/*  Address Contact*/}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0">
                            Address Cont.
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Group className="address py-2 ">
                            <div className="input_field">
                              <div className="d-flex align-items-center position-relative">
                                <Form.Control
                                  className={`
                                      form-control registration_field
                                      ${
                                        formData.address_contact &&
                                        "sucess_active"
                                      }`}
                                  type="text"
                                  name="address_contact"
                                  value={formData?.address_contact || ""}
                                  onChange={handleChange}
                                  maxLength={55}
                                  placeholder="Enter Address"
                                  onKeyPress={handleKeyPresss}
                                  onPaste={handlePaste}
                                />
                                <div
                                  className="toggle_globe position-absolute"
                                  ref={addressContactRef}
                                >
                                  <i
                                    className="fa fa-globe position-relative"
                                    aria-hidden="true"
                                    onClick={() =>
                                      handleIconClick("addressContact")
                                    }
                                  ></i>
                                  {showSelects.addressContact && (
                                    <select
                                      onChange={(e) =>
                                        handleChanges("addressContact", e)
                                      }
                                      value={selectedValues.addressContact}
                                      className="selection"
                                      style={{ zIndex: "99" }}
                                    >
                                      <option value="">
                                        <i
                                          className="fa fa-globe"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Set As Public
                                      </option>
                                      <option value="addressContact">
                                        <i
                                          className="fa fa-lock"
                                          aria-hidden="true"
                                        ></i>{" "}
                                        Set As Private
                                      </option>
                                    </select>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>

                    {/* Country*/}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0">
                            Country
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Group className="country  py-2">
                            <div className="input_field">
                              <Select
                                className={`
                                     ${
                                       ((errors.country &&
                                         selectedCountry?.length === 0) ||
                                         errors.country ||
                                         selectedCountry === null) &&
                                       "error_active"
                                     }
                                    ${
                                      errors.country === "" &&
                                      selectedCountry &&
                                      selectedCountry !== null &&
                                      "sucess_active"
                                    }`}
                                name="country"
                                label="Country"
                                options={countries}
                                value={selectedCountry}
                                onChange={handleCountryChange}
                                components={{ DropdownIndicator }}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                      </Row>
                    </div>
                    {/* Province */}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0">
                            Province
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Group className="province  py-2">
                            <div className="input_field">
                              <Select
                                className={`
                                          ${
                                            ((errors.province &&
                                              selectedProvince?.length === 0) ||
                                              errors.province ||
                                              selectedProvince === null) &&
                                            "error_active"
                                          }
                                          ${
                                            errors.province === "" &&
                                            selectedProvince &&
                                            selectedProvince !== null &&
                                            "sucess_active"
                                          }`}
                                id="province"
                                name="province"
                                value={selectedProvince}
                                onChange={handleStateChange}
                                options={province}
                                placeholder="Search for a Province"
                                isSearchable
                                isDisabled={isdisabled}
                                components={{ DropdownIndicator }}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={8}>
                          <span style={{ color: "red" }}>
                            {errors.province}
                          </span>
                        </Col>
                      </Row>
                    </div>
                    {/* City / Town*/}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0">
                            City / Town
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Group className="city  py-2">
                            <div className="input_field">
                              <Select
                                className={`
                                          ${
                                            ((errors.city &&
                                              selectedCity?.length === 0) ||
                                              errors.city ||
                                              selectedCity === null) &&
                                            "error_active"
                                          }
                                          ${
                                            errors.city === "" &&
                                            selectedCity &&
                                            selectedCity !== null &&
                                            "sucess_active"
                                          }`}
                                name="city"
                                label="city"
                                value={selectedCity}
                                onChange={handleCityChange}
                                options={city}
                                placeholder="Search for a city"
                                isSearchable
                                isDisabled={iscitydisabled}
                                components={{ DropdownIndicator }}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={8}>
                          <span style={{ color: "red" }}>{errors.city}</span>
                        </Col>
                      </Row>
                    </div>
                    {/* Postal Code */}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0">Postal Code</Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Group className="postal_code py-2">
                            <div className="input_field">
                              <Form.Control
                               className={`form-control 
                                      ${
                                        ((errors.postal_code &&
                                          formData.postal_code === "") ||
                                          errors.postal_code) &&
                                        "error_active"
                                      }
                                      ${
                                        errors.postal_code === "" &&
                                        formData.postal_code &&
                                        "sucess_active"
                                      }`}
                                type="text"
                                name="postal_code"
                                maxLength={10}
                                value={formData?.postal_code || ""}
                                onChange={handleChange}
                                placeholder="Enter Postal Code"
                                onKeyPress={handleKeyPresss}
                                onPaste={handlePaste}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={8}>
                          <span style={{ color: "red" }}>
                            {errors.postal_code}
                          </span>
                        </Col>
                      </Row>
                    </div>
                    {/* Phone */}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0">
                            Phone
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <div className="d-flex align-content-center position-relative">
                            <Form.Group className="code  py-2">
                              <div className="select_code">
                                <input
                                  className="form-control"
                                  id="phone_code"
                                  type="text"
                                  name="phone_code"
                                  value={phoneCodeFormat(
                                    formData.phone_code || " "
                                  )}
                                  onChange={handleChange}
                                  onKeyPress={handleKeyPresss}
                                  disabled
                                />
                              </div>
                            </Form.Group>
                            <Form.Group className="phone  py-2 ms-2 w-100">
                              <div className="input_field">
                                <div className="d-flex align-items-center">
                                  <Form.Control
                                    className={`
                                        form-control registration_field ms-1
                                         ${
                                           ((errors.phone &&
                                             formData.phone === "") ||
                                             isPhoneValid ===
                                               "Already Exists" ||
                                             errors.phone) &&
                                           "error_active"
                                         }
                                      ${
                                        errors.phone === "" &&
                                        formData.phone &&
                                        isPhoneValid === "" &&
                                        "sucess_active"
                                      }
                                    `}
                                    id="phone"
                                    type="text"
                                    name="phone"
                                    maxLength={14}
                                    value={phoneFormat(formData?.phone || "")}
                                    onKeyPress={handleKeyPress}
                                    onChange={handleChange}
                                    onPaste={handlePaste}
                                    placeholder="Enter Phone Number"
                                  />
                                  <div
                                    className="toggle_globe position-absolute"
                                    ref={phoneRef}
                                  >
                                    <i
                                      className="fa fa-globe position-relative"
                                      aria-hidden="true"
                                      onClick={() => handleIconClick("phone")}
                                    ></i>
                                    {showSelects.phone && (
                                      <select
                                        onChange={(e) =>
                                          handleChanges("phone", e)
                                        }
                                        value={selectedValues.phone}
                                        className="selection"
                                        style={{ backgroundColor: "white" }}
                                      >
                                        <option value="">
                                          <i
                                            className="fa fa-globe"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Set As Public
                                        </option>
                                        <option value="phone">
                                          <i
                                            className="fa fa-lock"
                                            aria-hidden="true"
                                          ></i>{" "}
                                          Set As Private
                                        </option>
                                      </select>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Form.Group>
                          </div>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={8}>
                          {isPhoneValid === "Already Exists" ? (
                            <span className="text-danger mt-2">
                              Phone Number Already Exists. Please choose a
                              different one.
                            </span>
                          ) : (
                            <span className="ms-1" style={{ color: "red" }}>
                              {errors.phone}
                            </span>
                          )}
                        </Col>
                      </Row>
                    </div>
                    {/* Fax */}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0">Fax</Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Group className="fax  py-2 ">
                            <div className="input_field">
                              <Form.Control
                                className={`
                                    form-control
                                      ${
                                        ((errors.fax && formData.fax === "") ||
                                          errors.fax) &&
                                        "error_active"
                                      }
                                      ${
                                        errors.fax === "" &&
                                        formData.fax &&
                                        "sucess_active"
                                      }
                                    `}
                                type="text"
                                name="fax"
                                maxLength={10}
                                value={formData?.fax || ""}
                                onChange={handleChangeWebsiteFix}
                                placeholder="Enter Fax"
                                onPaste={handlePaste}
                                onKeyPress={handleKeyPresss}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={8}>
                          <span style={{ color: "red" }}> {errors.fax} </span>
                        </Col>
                      </Row>
                    </div>
                    {/* Industry Preferences */}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0 ">
                            Industry Preferences{" "}
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Group className="industry_preferences py-2">
                            <div
                              className={`
                                input_checkbox form-control  p-2 rounded Preferences_check
                                  ${
                                    errors.industry_preferences &&
                                    selectedCheckboxes?.length === 0 &&
                                    "error_active"
                                  }
                                  ${
                                    errors.industry_preferences === "" &&
                                    selectedCheckboxes?.length > 0 &&
                                    "sucess_active"
                                  }
                                `}
                            >
                              <div className="form-check mb-2">
                                <input
                                  className="form-check-input"
                                  name="industry_preferences"
                                  type="checkbox"
                                  id="flexCheckDefault1"
                                  value="Select All / None"
                                  onChange={handleCheckboxChangeAll}
                                  checked={selectedCheckboxes?.length >= 8}
                                  onKeyPress={handleKeyPresss}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault1"
                                >
                                  (Select All / None)
                                </label>
                              </div>
                              {[
                                "Private Placements Mining",
                                "Private Placements Oil and Gas",
                                "Private Placements - Other",
                                "Portfolio Management with Exempt Clients",
                                "Mergers and Acquisitions",
                                "Private Placements - Real Estate",
                                "Distributor of Exempt Investments",
                                "Other",
                              ].map((label, index) => (
                                <div className="form-check mb-2" key={index}>
                                  <input
                                    className="form-check-input"
                                    name="industry_preferences"
                                    type="checkbox"
                                    value={label}
                                    id={`flexCheckChecked${index + 2}`}
                                    onChange={handleCheckboxChange}
                                    checked={selectedCheckboxes?.includes(
                                      label
                                    )}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={`flexCheckChecked${index + 2}`}
                                  >
                                    {label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={8}>
                          <span style={{ color: "red", position: "absolute" }}>
                            {" "}
                            {errors.industry_preferences}{" "}
                          </span>
                        </Col>
                      </Row>
                    </div>
                    {/* Company Description */}
                    <div className="col-md-6">
                      <Row className="align-items-center">
                        <Col sm={4}>
                          <Form.Label className="mb-0">
                            Company Description{" "}
                            <svg
                              className="ms-1"
                              xmlns="http://www.w3.org/2000/svg"
                              width="9"
                              height="9"
                              viewBox="0 0 9 9"
                              fill="none"
                            >
                              <path
                                d="M8.2249 5.7873L7.5874 6.8998L5.5124 5.69981C5.37074 5.60814 5.2374 5.51647 5.1124 5.4248C4.9874 5.33314 4.87907 5.22897 4.7874 5.1123C4.90407 5.37064 4.9624 5.67064 4.9624 6.0123V8.3998H3.6624V6.0373C3.6624 5.67897 3.70824 5.37897 3.7999 5.1373C3.64157 5.34564 3.4124 5.53314 3.1124 5.69981L1.0499 6.8873L0.412402 5.7748L2.4624 4.58731C2.7624 4.4123 3.05824 4.2998 3.3499 4.2498C3.05824 4.21647 2.7624 4.10397 2.4624 3.9123L0.399902 2.7123L1.0374 1.5998L3.1124 2.79981C3.25407 2.89147 3.38324 2.9873 3.4999 3.08731C3.6249 3.17897 3.73324 3.28314 3.8249 3.3998C3.71657 3.13314 3.6624 2.82897 3.6624 2.4873V0.112305H4.9624V2.4748C4.9624 2.64147 4.9499 2.7998 4.9249 2.9498C4.90824 3.0998 4.87074 3.24147 4.8124 3.37481C5.00407 3.1498 5.2374 2.95814 5.5124 2.79981L7.5749 1.6123L8.2124 2.7248L6.1624 3.9123C6.02074 3.99564 5.87907 4.07064 5.7374 4.13731C5.59574 4.19564 5.45407 4.23314 5.3124 4.2498C5.45407 4.2748 5.59574 4.31647 5.7374 4.3748C5.87907 4.4248 6.02074 4.49564 6.1624 4.58731L8.2249 5.7873Z"
                                fill="#F92828"
                              />
                            </svg>
                          </Form.Label>
                        </Col>
                        <Col sm={8}>
                          <Form.Group className="company_description py-2">
                            <div className="input_field">
                              <Form.Control
                                className={`
                                      form-control
                                        ${
                                          ((errors.company_description &&
                                            formData.company_description ===
                                              "") ||
                                            errors.company_description) &&
                                          "error_active"
                                        }
                                        ${
                                          errors.company_description === "" &&
                                          formData.company_description &&
                                          "sucess_active"
                                        }
                                `}
                                type="text"
                                value={formData?.company_description || ""}
                                name="company_description"
                                onChange={handleChange}
                                maxLength={500}
                                onPaste={handlePaste}
                                placeholder="Enter Company Description "
                                onKeyPress={handleKeyPresss}
                              />
                            </div>
                          </Form.Group>
                        </Col>
                        <Col sm={4}></Col>
                        <Col sm={8}>
                          {" "}
                          <span style={{ color: "red" }}>
                            {" "}
                            {errors.company_description}{" "}
                          </span>
                        </Col>
                      </Row>
                    </div>
                    <div className="col-12">
                      <div className="save d-flex">
                        <Button
                          className="btn_save_form ms-sm-auto  border-0"
                          style={{ padding: "8px 27px" }}
                          disabled={isButtonDisabled}
                          onClick={handleSubmit}
                          type="button"
                        >
                          Save
                        </Button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className="d-flex justify-content-center align-items-center">
        <Modal show={showModal} onHide={handleClose} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#0F66B4" }}>Notifications</span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="container-fluid p-4">
              <div
                className="img_container p-3"
                style={{
                  width: "71px",
                  height: "71px",
                  borderRadius: "12.35px",
                  backgroundColor: "rgba(221, 235, 242, 0.6)",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="39"
                  height="39"
                  viewBox="0 0 39 39"
                  fill="none"
                  className=""
                >
                  <path
                    d="M14.6091 26.6064L19.5 23.6384L24.3909 26.6064L23.095 21.0467L27.4425 17.2844L21.7155 16.8246L19.5 11.5575L17.2844 16.8246L11.5575 17.2844L15.905 21.0467L14.6091 26.6064ZM19.5 38.3947L13.8984 32.8768H6.12316V25.1015L0.605225 19.5L6.12316 13.8984V6.12316H13.8984L19.5 0.605225L25.1015 6.12316H32.8768V13.8984L38.3947 19.5L32.8768 25.1015V32.8768H25.1015L19.5 38.3947ZM19.5 33.7128L23.6802 29.5326H29.5326V23.6802L33.7128 19.5L29.5326 15.3197V9.46736H23.6802L19.5 5.28711L15.3197 9.46736H9.46736V15.3197L5.28711 19.5L9.46736 23.6802V29.5326H15.3197L19.5 33.7128Z"
                    fill="#0F66B4"
                  />
                </svg>
              </div>
              <div className="modal_body">
                <h4>{notificationpurpose}</h4>
                <div className="notification_message">
                  <p style={{ width: "100%", maxHeight: "170px" }}>
                    {notificationmessage}
                  </p>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer className="border-0"></Modal.Footer>
        </Modal>
      </div>
    </>
  );
};
export default ProfileSettings;
