import React, { useState, useEffect} from "react";
import {Container, Row, Col, Form, Button} from "react-bootstrap";
import axios from "axios";
import { toast } from "react-hot-toast";
import { BallTriangle } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { setPageNumber } from '../../redux/slice/firmdNumber.js';

const AdminProfile = () => {
  const [show, setShow] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmpasswordType, setCnfirmpasswordType] = useState("password");
  const [isLoading, setIsLoading] = useState(false);
  const [confirm_Button, setConfirm_Button] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    new_password: '',
    confirm_password: ''
  });

  const [errors, setErrors] = useState({
    new_password: '',
    confirm_password:''
  });
  const { admin: { username, email, name, role, _id}, Token } = JSON.parse(localStorage.getItem("loginAdmin"));
  let newErrors = { ...errors };


  useEffect(() => {
    document.title = "PCMA || Admin Profile";
  }, []);
    
  dispatch(setPageNumber(1));
  const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value?.trimStart();
    const filteredValue = trimmedValue?.replace(emojiRegex, '');
    setFormData({
      ...formData,
      [name]: filteredValue,
    });
    passwordhandal(name, filteredValue)
  };
  
  const passwordhandal = (name, value) => {
    if (name === 'new_password') {
      formData.new_password = value;
      const isValidPassword = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$_#^~!%*?&])[A-Za-z\d@$!%_#^~*?&]{8,}$/.test(value);
      if (value) {
        newErrors.new_password = '';
        if (!isValidPassword) {
          newErrors.new_password = 'Password must be 8 to 20 characters and one Number, one Special character and one Capital letter.';
        }
        else {
          newErrors.new_password = '';
        }
      } else {
        newErrors.new_password = 'Password is required.';
      }
    }

    if (name === 'confirm_password') {
      formData.confirm_password = value;
      if (value) {
        if (formData?.new_password === value) {
          newErrors.confirm_password = '';
        }
        else {
          newErrors.confirm_password = 'Password does not match';
        }
      }
      else {
        newErrors.confirm_password = 'Confirm password is required.'
      }
    }

    setErrors(newErrors);

  };

  const preventSpace = (event) => {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }
  // console.log('errors', errors);

const handlepwd = () => {
  setShow((prevShow) => !prevShow);
};
const togglePassword = () => {
  if (passwordType === "password") {
    setPasswordType("text");
    return;
  }
  setPasswordType("password");
  };
  
const toggleconfirmPassword = () => {
  if (confirmpasswordType === "password") {
    setCnfirmpasswordType("text");
    return;
  }
  setCnfirmpasswordType("password");
  };

  const validateForm = () => {
    for (const fieldName in formData) {
      if (formData.hasOwnProperty(fieldName)) {
        passwordhandal(fieldName, formData[fieldName]);
      
      }
    }
    setIsLoading(false);
    setConfirm_Button(false);
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();  
    setIsLoading(true);
    validateForm();
    if (!Object.values(newErrors).some((error) => error !== '')) {
      try {   
        setConfirm_Button(true);
        const { data } = await axios.put(
          `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/profile-update-admin/${_id}`,
          {
            new_password: formData?.new_password,
          },
          {
            headers: {
              Authorization: `Bearer ${Token}`,
            },
          }
        );

        if (data?.data?.message === "Unauthozied User") {
          localStorage.removeItem('loginAdmin');
          navigate("/admin");
          toast.error(`Unauthozied user`);
        }
        setIsLoading(false);
        setConfirm_Button(true);
        setShow(false);
        if (data.message === "Password updated successfully") {
          toast.success(data.message);
          setTimeout(() => {
            setConfirm_Button(false);
          }, 5000);
          setShow(false);
          setFormData({});
          setIsLoading(false);
        } else {
          toast.error(data.message);
        }
      } catch (error) {
        if (error?.response?.data?.message === "Unauthozied User") {
          localStorage.removeItem('loginAdmin');
          navigate("/admin");
          toast.error(`Unauthozied user !`);
        } else {
          console.error(`Error: ${error}`);
        };
        setConfirm_Button(false);
        setIsLoading(false);
      };
    };
  };
  
  return (
    <>
      {isLoading ? (
        <Container fluid className="loader">
          <Row
            className="g-2 pb-3 d-flex justify-content-center align-content-center "
            style={{ height: "79vh" }}
          >
            <Col
              lg={12}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-content-center"
            >
              <BallTriangle
                height={100}
                width={100}
                radius={5}
                color="#0f66b4"
                ariaLabel="ball-triangle-loading"
                wrapperClass={{}}
                wrapperStyle=""
                visible={true}
              />
            </Col>
          </Row>
        </Container>
      ) : (
        <>
          <Container fluid>
          <Row className="mt-2">
          <Col  xs={12} md={12}  lg={12} style={{ background: "#137CD9", color: "#fff", padding: "4px 8px", marginTop:"35px", borderRadius:"5px", }}>

                <h4>Admin Profile</h4>
              </Col>
            </Row>
          </Container>
          <Container fluid className="mt-3 profile_section pb-3">
            <Row>
              <Col xs={12} md={12} lg={12} xxl={12} className="mb-4">
                <div className="d-flex justify-content-between p-3 mt-4 admin_info">
                  <div className="d-flex">
                    <div className="position-relative">
                      <img
                       src="/asset/users.png"
                        alt="ceoimage"
                        className="img-fluid ms-2"
                        style={{
                          height: "50px",
                          width: "50px",
                          borderRadius: "50%",
                        }}
                      />
                    </div>

                    <div className="px-3">
                      <div>
                         <span style={{fontWeight:600}}>Name :</span> {name}
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 19H6.425L16.2 9.225L14.775 7.8L5 17.575V19ZM3 21V16.75L16.2 3.575C16.4 3.39167 16.621 3.25 16.863 3.15C17.105 3.05 17.359 3 17.625 3C17.8917 3 18.15 3.05 18.4 3.15C18.65 3.25 18.8667 3.4 19.05 3.6L20.425 5C20.625 5.18333 20.771 5.4 20.863 5.65C20.955 5.9 21.0007 6.15 21 6.4C21 6.66667 20.9543 6.921 20.863 7.163C20.7717 7.405 20.6257 7.62567 20.425 7.825L7.25 21H3ZM15.475 8.525L14.775 7.8L16.2 9.225L15.475 8.525Z"
                            fill="#7C7C7C"
                          />
                        </svg> */}
                      </div>
                      <div>
                      <span style={{fontWeight:600}}>Role :</span> {role}
                        {/* <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M5 19H6.425L16.2 9.225L14.775 7.8L5 17.575V19ZM3 21V16.75L16.2 3.575C16.4 3.39167 16.621 3.25 16.863 3.15C17.105 3.05 17.359 3 17.625 3C17.8917 3 18.15 3.05 18.4 3.15C18.65 3.25 18.8667 3.4 19.05 3.6L20.425 5C20.625 5.18333 20.771 5.4 20.863 5.65C20.955 5.9 21.0007 6.15 21 6.4C21 6.66667 20.9543 6.921 20.863 7.163C20.7717 7.405 20.6257 7.62567 20.425 7.825L7.25 21H3ZM15.475 8.525L14.775 7.8L16.2 9.225L15.475 8.525Z"
                            fill="#7C7C7C"
                          />
                        </svg> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Col>

              <Col xxl={6} md={6} sm={12}>
                <Form.Group className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center py-2">
                  <Form.Label className="form_label">Username</Form.Label>
                  <div className="w-100">
                    <Form.Control
                      type="text"
                      name="username"
                      value={username}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid-feedback text-start p-0 ps-sm-2"></Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>

              <Col xxl={6} md={6} sm={12}>
                <Form.Group className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center py-2">
                  <Form.Label className="form_label">Email Address</Form.Label>
                  <div className="w-100">
                    <Form.Control
                      type="email"
                      name="emailaddress"
                      value={email}
                      disabled
                    />
                    <Form.Control.Feedback type="invalid-feedback text-start p-0 ps-sm-2"></Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
              <Col xxl={6} md={6} sm={12}>
                <Form.Group className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center py-2">
                  <Form.Label className="form_label">Password</Form.Label>
                  <div className="w-100">
                    <Form.Control
                      type="password"
                      name="password"
                      disabled
                      placeholder="***********"
                    />
                    <Form.Control.Feedback type="invalid-feedback text-start p-0 ps-sm-2"></Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Col>
              <Col xxl={4} md={4} sm={12}>
                <div className="d-sm-flex align-items-center mt-2">
                  <Button
                    className="confirm_pwd   border-0"
                    type="button"
                    onClick={handlepwd}
                  >
                    {show ? "Reset" : "Change Password"}
                  </Button>
                </div>
              </Col>
              {show === true ? (
                  <>
                    <Col xxl={6} md={6} sm={12}>
                      <Form.Group className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center py-2">
                        <Form.Label className="form_label">New Password</Form.Label>
                        <div className="w-100">
                        <div className=" position-relative">
                          
                          <Form.Control
                            className={`form-control registration_field
                              ${(((errors.new_password) && (formData.new_password === '')) || (errors.new_password)) && 'error_active'}
                              ${((errors.new_password === '') && (formData.new_password)) && 'sucess_active'}`}
                              type={passwordType}
                              value={formData?.new_password}
                              name="new_password"
                              placeholder="Enter New Password "
                              onChange={handleChange}
                              onKeyDown={preventSpace}
                          />
                          <button
                              type="button"
                              className="bg-transparent border-0 position-absolute eyBtn"
                              onClick={togglePassword}
                            >
                              {passwordType === "password" ? (
                                <i className="fa-regular fa-eye-slash"></i>
                              ) : (
                                <i className="fa-regular fa-eye"></i>
                              )}
                          </button>
                          </div>
                        <span style={{ color: "red" }}> {errors.new_password}</span>
                        </div>
                       </Form.Group>
                    </Col>
                    <Col xxl={6} md={6} sm={12}></Col>
                    <Col xxl={6} md={6} sm={12}>
                      <Form.Group className="d-flex flex-column flex-sm-row align-items-start align-items-sm-center py-2">
                        <Form.Label className="form_label"> Re-Type Password</Form.Label>
                        <div className="w-100">
                          <div className=" position-relative">
                          <Form.Control
                            className={`form-control
                            ${(((errors.confirm_password) && (formData.confirm_password === '')) || (errors.confirm_password)) && 'error_active'}
                            ${((errors.confirm_password === '') && (formData.confirm_password)) && 'sucess_active'}`}
                              type={confirmpasswordType}
                              name="confirm_password"
                              value={formData?.confirm_password}
                              placeholder="Re-Type Password"
                              onChange={handleChange}
                              onKeyDown={preventSpace}
                          />
                          
                             <button
                              type="button"
                              className="bg-transparent border-0 position-absolute eyBtn"
                              onClick={toggleconfirmPassword}
                            >
                              {confirmpasswordType === "password" ? (
                                <i className="fa-regular fa-eye-slash"></i>
                              ) : (
                                <i className="fa-regular fa-eye"></i>
                              )}
                          </button>
                          </div>
                          <span style={{ color: "red" }}> {errors.confirm_password}</span>
                        </div>
                       </Form.Group>
                    </Col>
                    <Col xxl={4} md={4} sm={12}>
                    <div className="d-sm-flex align-items-center mt-2">
                      <Button
                        className="confirm_pwd   border-0"
                        type="submit"
                        disabled={confirm_Button}
                        onClick={handleSubmit}
                      >
                        Confirm Password
                      </Button>
                    </div>
                  </Col>
                </>
              ) : (
                " "
              )}
            </Row>
          </Container>
        </>
      )}
    </>
  );
};
export default AdminProfile;
