import React from "react";
import { Link } from "react-router-dom";

const Footer = (props) => {
  return (
    <div className="w-100">
      <section className="footer member-information  px-3">
        <div
          className="slider_img_div_box"
          style={{ maxWidth: props?.Width, padding: props?.Padding }}
        >
          <div className="container-fluid">
            <div className="row py-4 footer_firstpart align-items-center">
              <div className="col-lg-3 col-xl-4">
                <div className="footer_img">
                  {props.wordpressUrl ? (
                    <Link to={`${props.wordpressUrl}`}>
                      <img
                        src="/asset/new-footer-logo.png"
                        className="footer_img_logo"
                        alt=""
                        // style={{ maxWidth: "150px"}}
                      />
                    </Link>
                  ) : (
                    <Link to="/">
                      <img
                        src="/asset/new-footer-logo.png"
                        className="footer_img_logo"
                        alt=""
                        // style={{ maxWidth: "150px"}}
                      />
                    </Link>
                  )}
                </div>
              </div>

              <div className="col-lg-9 col-xl-8 mt-2 mt-lg-0 d-flex align-items-center justify-content-center justify-content-md-start">
                <div className="row fottor_top_row g-3 h-100 align-items-center">
                  <div className="col-6 col-lg-3 col-xxl-3 d-flex justify-content-center justify-content-md-start mt-0 mb-2">
                    <ul className="pcma text-md-start text-center mb-0 ps-0">
                      <li>
                        <h2 className="widget-title text-md-start text-center fw-bold">
                        PCMA
                        </h2>
                        <ul className="ps-0">
                          <li className="footer_links">
                            <Link
                              className="text-decoration-none"
                              to="https://pcma1dev.wpenginepowered.com/about-us-our-story/"
                            >
                              Our Story
                            </Link>
                          </li>
                          <li className="footer_links">
                            <Link
                              className="text-decoration-none"
                              to="https://pcma1dev.wpenginepowered.com/leadership-landing-page-v4/"
                            >
                              Leadership Landing Page
                            </Link>
                          </li>
                          <li className="footer_links ">
                            <Link
                              to="https://pcma1dev.wpenginepowered.com/leadership-landing-page-v4/"
                              className="text-decoration-none"
                            >
                              Committees Landing Page
                            </Link>
                          </li>
                          <li></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 col-lg-3 col-xxl-3 d-flex justify-content-center justify-content-md-start">
                    <ul className="list-unstyled membership pcma text-md-start text-center mb-0 ">
                      <li>
                        <h2 className="widget-title text-md-start text-center fw-bold">
                          MEMBERSHIP
                        </h2>
                        <ul className="ps-0">
                          <li className="footer_links">
                            <Link
                              to="https://pcma1dev.wpenginepowered.com/membership-become-a-member-2/"
                              className="text-decoration-none"
                            >
                              Become A Member
                            </Link>
                          </li>
                          <li className="footer_links">
                            <Link
                              to="https://dev.pcmacanada.klizos.com/our-members"
                              className="text-decoration-none"
                            >
                              Our Members
                            </Link>
                          </li>
                          <li className="footer_links">
                            <Link
                              to="https://pcma1dev.wpenginepowered.com/membership-membership-benefits/"
                              className="text-decoration-none"
                            >
                              Membership Benefits
                            </Link>
                          </li>
                          <li className="footer_links ">
                            <Link
                              to="https://pcma1dev.wpenginepowered.com/membership-benefits-member-tuition-discounts-v4/"
                              className="text-decoration-none"
                            >
                              Member Tuition Discounts
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 col-lg-3 col-xxl-3 d-flex justify-content-center justify-content-md-start">
                    <ul className="list-unstyled quick_links pcma text-md-start text-center mb-0">
                      <li>
                        <h2 className="widget-title text-md-start text-center fw-bold">
                          QUICK LINKS
                        </h2>
                        <ul className="ps-0">
                          <li className="footer_links">
                            <Link
                              to="https://pcma1dev.wpenginepowered.com/events-calendar/"
                              className="text-decoration-none"
                            >
                              Events Calendar
                            </Link>
                          </li>
                          <li className="footer_links">
                            <Link
                              to="https://pcma1dev.wpenginepowered.com/sponsorship-opportunities-v4/"
                              className="text-decoration-none"
                            >
                              Sponsorship Opportunities
                            </Link>
                          </li>
                          <li className="footer_links">
                            <Link
                              to="https://pcma1dev.wpenginepowered.com/advocacy-comment-letters-2/"
                              className="text-decoration-none"
                            >
                              Comment Letters
                            </Link>
                          </li>
                          <li className="footer_links">
                            <Link
                              to="https://pcma1dev.wpenginepowered.com/resources-ifse-emp-course-v4/"
                              className="text-decoration-none"
                            >
                              IFSE EMP Course
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="col-6 col-lg-3 col-xxl-3 d-flex justify-content-center justify-content-md-start mb-2">
                    <ul className="list-unstyled resources pcma text-md-start text-center">
                      <li>
                        <h2 className="widget-title text-md-start text-center fw-bold">
                          RESOURCES
                        </h2>
                        <ul className="ps-0">
                          <li className="footer_links">
                            <Link
                              to="https://pcma1dev.wpenginepowered.com/press-guidelines-v4/"
                              className="text-decoration-none"
                            >
                              Press Guidelines
                            </Link>
                          </li>

                          <li className="footer_links">
                            <Link
                              to="https://pcma1dev.wpenginepowered.com/rss-feeds-v4/"
                              className="text-decoration-none"
                            >
                              RSS Feed
                            </Link>
                          </li>
                          <li className="footer_links">
                            <Link
                              to="https://pcma1dev.wpenginepowered.com/chapters-landing-page-v4/"
                              className="text-decoration-none"
                            >
                              Chapters Landing Page
                            </Link>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="footer_secondpart row g-3 d-flex py-4">
              <div className="col-xxl-8 col-xl-8 col-lg-8">
                <div className="d-flex justify-content-center  justify-content-md-start">
                  <div className="soutions">
                    <div className="address mt-3">
                      <h3 className="company">
                        PRIVATE CAPITAL MARKETS ASSOCIATION OF CANADA
                      </h3>
                      <p className="company_address">
                        Suite 5700, First Canadian Place 100 King Street West,
                        Toronto, ON, M5X 1C7
                      </p>
                      <p className="company_address mb-0">
                        Tel:
                        <a
                          href="tel:1.877.363.3632"
                          className="text-white text-decoration-none "
                        >
                          1.877.363.3632
                        </a>{" "}
                        | Email:{" "}
                        <Link
                          to="mailto:info@pcmacanada.com"
                          className="text-white text-decoration-none "
                        >
                          info@pcmacanada.com
                        </Link>
                      </p>
                      <div className=" d-flex flex-column flex-sm-row aliign-items-center justify-content-center justify-content-md-start  trams_and_conditions mt-4">
                        <span className=" d-inline-block pe-sm-3 text-center text-sm-start">
                          <Link
                            to="https://pcma1dev.wpenginepowered.com/terms-conditions-v4/"
                            target="_blank"
                          >
                            Terms & Conditions
                          </Link>
                        </span>
                        <span
                          className=" d-inline-block px-sm-3 text-center text-sm-start"
                          style={{
                            borderLeft: "1px solid #ffffff80",
                            borderRight: "1px solid #ffffff80",
                          }}
                        >
                          <Link
                            to="https://pcma1dev.wpenginepowered.com/privacy-policy/"
                            target="_blank"
                          >
                            Privacy Policy
                          </Link>
                        </span>
                        <span className=" d-inline-block ps-sm-3 text-center text-sm-start">
                          <Link
                            to="https://pcma1dev.wpenginepowered.com/inclusivity-and-accessibility-v4/"
                            target="_blank"
                          >
                            Inclusivity and Accessibility
                          </Link>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4">
                <div className="row justify-content-end">
                  <div className="col-xl-6 col-lg-6 d-flex justify-content-center justify-content-md-start justify-content-lg-center">
                    <div className="social_media mt-5 mt-lg-0">
                      <h6 className="mb-2 text-white text-center text-md-start">
                        Follow Us :
                      </h6>
                      <div className="social_media_icons d-flex">
                        <Link
                          target="_blank"
                          to="https://www.facebook.com/PCMACanada"
                          className="social_media_link text-decoration-none me-3"
                        >
                          <i className="fa-brands fa-facebook-f fs-4"></i>
                        </Link>
                        <Link
                          target="_blank"
                          to="https://twitter.com/pcmacanada?lang=en"
                          className="social_media_link text-decoration-none me-3"
                        >
                          <i className="fa-brands fa-x-twitter fs-4"></i>
                        </Link>
                        <Link
                          target="_blank"
                          to="https://www.instagram.com/pcma_canada"
                          className="social_media_link text-decoration-none me-3"
                        >
                          <i className="fa-brands fa-instagram fs-4"></i>
                        </Link>
                        <Link
                          target="_blank"
                          to="https://www.linkedin.com/company/exempt-market-dealers-association-of-canada/?originalSubdomain=in"
                          className="social_media_link text-decoration-none "
                        >
                          {/* <i className="fa-brands fa-linkedin fs-4"></i> */}
                          <svg
                            style={{ position: "relative", top: "-1px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            fill="currentColor"
                            className="bi bi-linkedin"
                            viewBox="0 0 16 16"
                          >
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                          </svg>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="px-3" style={{ backgroundColor: "#2678C0" }}>
        <div
          className="slider_img_div_box d-md-flex justify-content-between"
          style={{ maxWidth: props?.Width, padding: props?.Padding }}
        >
          <p
            className="p-sm-2 text-white text-center text-md-start  mb-0"
            style={{ fontSize: "14px" }}
          >
            All Rights Reserved.
          </p>
          <p
            className="p-sm-2 text-white text-center text-md-start   mb-0"
            style={{ fontSize: "14px" }}
          >
            Made with {"  "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="15"
              viewBox="0 0 16 15"
              fill="none"
            >
              <path
                d="M8 14.68L6.84 13.624C2.72 9.888 0 7.416 0 4.4C0 1.928 1.936 0 4.4 0C5.792 0 7.128 0.648 8 1.664C8.872 0.648 10.208 0 11.6 0C14.064 0 16 1.928 16 4.4C16 7.416 13.28 9.888 9.16 13.624L8 14.68Z"
                fill="white"
              />
            </svg>{" "}
            {"  "}
            by{" "}
            <a href="https://klizos.com/the-joygiver-page/" target="/blank">
              <img
                src="/asset/logo7.png"
                alt=""
                style={{ maxHeight: "20px" }}
              />{" "}
            </a>{" "}
            | A Partner and Sponsor of PCMA
          </p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
