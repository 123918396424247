import React, { useState, useEffect } from 'react'
import { Modal , Form , Button } from 'react-bootstrap';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Link, useNavigate } from 'react-router-dom';


const RegistrantFlagModal = ({ show, setShow, viewData, selectedId, api, token , registrantGroup }) => {

  const navigate = useNavigate();

    let targetName = viewData?.[0]?.target_name;
    const [disable, setDisable] = useState(false)
    const [fieldsVisible, setFieldsVisible] = useState({
        registarntName: false,
        registarntEmail: false,
        registrantWorkEmail: false,
        phoneNumber: false,
        linkedinLink: false,
        profileImage: false,
        location: false,
        city:false,
        country:false,
        province:false,
        postalCode:false,
        address:false,
        otherChecked:false,
        current_company_name:false,
        current_job_title:false,
        company_industry:false,
        company_linkedin_link:false,
    });

    const handleCheckboxChange = (field) => {
        setFieldsVisible({ ...fieldsVisible, [field]: !fieldsVisible[field] });
    };


    
  const isAnyFieldVisible = () => {
    return Object.values(fieldsVisible).some((visible) => visible === true);
  };


  const rejectAll = () => {
    if (!viewData || !viewData.length) return;
    const newFieldsVisible = {};
         viewData.forEach(data => {
          if (data?.name?.length > 0){
             newFieldsVisible['registarntName'] = false;
          }else{
            newFieldsVisible['registarntName'] = false;
          }
          if (data?.email?.length > 0){
              newFieldsVisible['registarntEmail'] = false;
          }else{
            newFieldsVisible['registarntEmail'] = false;
          }
          if (data?.registrant_work_email?.length > 0){
              newFieldsVisible['registrantWorkEmail'] = false;
          }else{
            newFieldsVisible['registrantWorkEmail'] = false;
          } 
          if (data?.phone?.length > 0){
            newFieldsVisible['phoneNumber'] = false;
          }else{
            newFieldsVisible['phoneNumber'] = false;
          }
          if (data?.linkedin_link?.length > 0){
            newFieldsVisible['linkedinLink'] = false;
          }else{
            newFieldsVisible['linkedinLink'] = false;
          }
             
          if (data?.profile_image?.length > 0){
            newFieldsVisible['profileImage'] = false;
          }else{
            newFieldsVisible['profileImage'] = false;
          }

          if (data?.city?.length > 0 && data?.province?.length > 0 && data?.province?.length > 0) {
            newFieldsVisible['address'] = false;
          } else {
            newFieldsVisible['address'] = false;
          }
           
          // if (data?.city?.length > 0){
          //   newFieldsVisible['city'] = true;
          // }else{
          //   newFieldsVisible['city'] = false;
          // } 
          // if (data?.country?.length > 0){
          //   newFieldsVisible['country'] = true;
          // }else{
          //   newFieldsVisible['country'] = false;
          // }
          // if (data?.province?.length > 0){
          //   newFieldsVisible['province'] = true;
          // }else{
          //   newFieldsVisible['province'] = false;
          // } 
          // if (data?.postal_code?.length > 0){
          //   newFieldsVisible['postalCode'] = true;
          // }else{
          //   newFieldsVisible['postalCode'] = false;
          // }
          // if (data?.location?.length > 0){
          //   newFieldsVisible['location'] = true;
          // }else{
          //   newFieldsVisible['location'] = false;
          // } 

          if (data?.current_company_name?.length > 0){
            newFieldsVisible['current_company_name'] = false;
          }else{
            newFieldsVisible['current_company_name'] = false;
          } 

          if (data?.current_job_title?.length > 0){
            newFieldsVisible['current_job_title'] = false;
          }else{
            newFieldsVisible['current_job_title'] = false;
          } 

          if (data?.company_industry?.length > 0){
            newFieldsVisible['company_industry'] = false;
          }else{
            newFieldsVisible['company_industry'] = false;
          } 
          if (data?.location?.length > 0){
            newFieldsVisible['company_linkedin_link'] = false;
          }else{
            newFieldsVisible['company_linkedin_link'] = false;
          } 

          if (data?.others?.length > 0){
            newFieldsVisible['otherChecked'] = false;
          }else{
            newFieldsVisible['otherChecked'] = false;
          } 
         });
          setFieldsVisible(newFieldsVisible);
        
    };


  const acceptAll = () => {
    if (!viewData || !viewData.length) return;
    const newFieldsVisible = {};
         viewData.forEach(data => {
          if (data?.name?.length > 0){
             newFieldsVisible['registarntName'] = true;
          }else{
            newFieldsVisible['registarntName'] = false;
          }
          if (data?.email?.length > 0){
              newFieldsVisible['registarntEmail'] = true;
          }else{
            newFieldsVisible['registarntEmail'] = false;
          }
          if (data?.registrant_work_email?.length > 0){
              newFieldsVisible['registrantWorkEmail'] = true;
          }else{
            newFieldsVisible['registrantWorkEmail'] = false;
          } 
          if (data?.phone?.length > 0){
            newFieldsVisible['phoneNumber'] = true;
          }else{
            newFieldsVisible['phoneNumber'] = false;
          }
          if (data?.linkedin_link?.length > 0){
            newFieldsVisible['linkedinLink'] = true;
          }else{
            newFieldsVisible['linkedinLink'] = false;
          }
             
          if (data?.profile_image?.length > 0){
            newFieldsVisible['profileImage'] = true;
          }else{
            newFieldsVisible['profileImage'] = false;
          }

          if (data?.province?.length > 0 && data?.country?.length > 0) {
            newFieldsVisible['address'] = true;
          } else {
            newFieldsVisible['address'] = false;
          }
           
          // if (data?.city?.length > 0){
          //   newFieldsVisible['city'] = true;
          // }else{
          //   newFieldsVisible['city'] = false;
          // } 
          // if (data?.country?.length > 0){
          //   newFieldsVisible['country'] = true;
          // }else{
          //   newFieldsVisible['country'] = false;
          // }
          // if (data?.province?.length > 0){
          //   newFieldsVisible['province'] = true;
          // }else{
          //   newFieldsVisible['province'] = false;
          // } 
          // if (data?.postal_code?.length > 0){
          //   newFieldsVisible['postalCode'] = true;
          // }else{
          //   newFieldsVisible['postalCode'] = false;
          // }
          // if (data?.location?.length > 0){
          //   newFieldsVisible['location'] = true;
          // }else{
          //   newFieldsVisible['location'] = false;
          // } 

          if (data?.current_company_name?.length > 0){
            newFieldsVisible['current_company_name'] = true;
          }else{
            newFieldsVisible['current_company_name'] = false;
          } 

          if (data?.current_job_title?.length > 0){
            newFieldsVisible['current_job_title'] = true;
          }else{
            newFieldsVisible['current_job_title'] = false;
          } 

          if (data?.company_industry?.length > 0){
            newFieldsVisible['company_industry'] = true;
          }else{
            newFieldsVisible['company_industry'] = false;
          } 
          if (data?.location?.length > 0){
            newFieldsVisible['company_linkedin_link'] = true;
          }else{
            newFieldsVisible['company_linkedin_link'] = false;
          } 

          if (data?.others?.length > 0){
            newFieldsVisible['otherChecked'] = true;
          }else{
            newFieldsVisible['otherChecked'] = false;
          } 
         });
          setFieldsVisible(newFieldsVisible);
        
    };


    const [feedback, setFeedback] = useState('')

    const emojiRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}]/u;
    
    const handleInputChange =(e)=>{
      const filteredValue = e?.target?.value.replace(emojiRegex, '');
      const trimmedValue = filteredValue?.trimStart();
      setFeedback(trimmedValue)
    }


    const handleSubmit = async () => {
        setDisable(true)
        // console.log("fieldsVisible",fieldsVisible)
        try {
          const response = await axios.put(
            `${process.env.REACT_APP_PCMA_BACKEND}/api/admin/flag/${selectedId}`,
            {
              approve_list: {
                name: fieldsVisible?.registarntName,
                email: fieldsVisible?.registarntEmail,
                registrant_work_email:fieldsVisible?.registrantWorkEmail,
                phone: fieldsVisible?.phoneNumber,
                linkedin_link: fieldsVisible?.linkedinLink,
                location: fieldsVisible?.location,
                country: fieldsVisible?.country,
                province: fieldsVisible?.province,
                city: fieldsVisible?.city,
                postal_code: fieldsVisible?.postalCode,
                profile_image: fieldsVisible?.profileImage,
                others: fieldsVisible?.otherChecked,
                current_company_name: fieldsVisible?.current_company_name,
                company_linkedin_link: fieldsVisible?.company_linkedin_link,
                current_job_title: fieldsVisible?.current_job_title,
                company_industry: fieldsVisible?.company_industry,
                address:fieldsVisible?.address
              },
              feedback: feedback,
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            }
          );
          if(response?.data?.data == "Flag status updated successfully"){
            toast.success(response?.data?.data);
            setShow(false); 
            registrantGroup(selectedId);  
            setFieldsVisible({
              registarntName: false,
              registarntEmail: false,
              registrantWorkEmail: false,
              phoneNumber: false,
              linkedinLink: false,
              profileImage: false,
              location: false,
              city:false,
              country:false,
              province:false,
              postalCode:false,
              address:false,
              otherChecked:false
            })
          }
          setDisable(false)
        } catch (error) {
        //   toast.error(error?.response?.data?.data);
          setDisable(false)
        }
    };

    const handleClose = () => {
        setShow(false);   
    };

    // const handleCopy = (text) => {
    //  navigate(text)
    // };

    return (
        <>
        <Modal centered backdrop="true" onHide={handleClose} keyboard={false} size="md" show={show}>
              <Modal.Header style={{ background: "#147DDB", borderRadius: "10px 10px 0px 0px" }}>
                <div className="d-flex justify-content-between w-100">
                  <h5 style={{ color: '#FFF' }}> {targetName}</h5>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" onClick={handleClose}  className=" custom-close-button" >
                    <rect width="24" height="24" rx="4" fill="white" fillOpacity="0.15" />
                    <path d="M7.86743 7.86816L15.6025 15.6033" stroke="white" strokeWidth="1.36739" strokeLinecap="round" />
                    <path d="M15.6023 7.86816L7.86719 15.6033" stroke="white" strokeWidth="1.36739" strokeLinecap="round" />
                  </svg>
                  {/* <Button variant="close" onClick={handleClose} /> */}
                </div>
              </Modal.Header>
              <Modal.Body>


              <div className="form p-3">
                {viewData && viewData?.map((data, i) => (
                    <div className="row" key={i} >
                      <div className="col-12 col-sm-12">
                        <Form.Group className="mb-3" controlId="flagReason">
                          <Form.Label style={{ fontWeight: 600,opacity:0.4 }}>Flag Reason</Form.Label>
                          <div className="d-flex align-items-center pt-1">

                            <p style={{ flex: 1,fontWeight:700,}}>{data?.reason}</p>
 
                          </div>
                        </Form.Group>
                      </div>

                    {data?.name?.length > 0 && (
                      <div className="col-12 col-sm-12 pt-1">
                        <p style={{ fontWeight: 600, opacity: 0.4 }}>Registered Individual Name</p>
                        <div className='pt-1 d-flex justify-content-between'>
                          <p style={{ fontWeight: 700 }}>
                            {data?.name}
                          </p> 
                          {data?.status !== "Pending"?  (data?.approval?.name  === true? <p className='text-success'>Accepted</p>: <p className='text-danger'>Rejected</p> ):""}
                          {data?.status === "Pending" &&
                          <div className='d-flex'>

                          <p className='px-1' style={{opacity:0.4}}>Select To accept</p>
                          <Form.Check
                          type="checkbox"
                          id={`checkbox-${data.registarntName}`}
                          label=""
                          className="ml-2"
                          checked={fieldsVisible['registarntName'] || false}
                          onChange={() => handleCheckboxChange('registarntName')}
                        />
                        </div>
                          }

                        </div>
                      
                      </div>
                    )}

                    {data.email?.length > 0 && (
                      <div className="col-12 col-sm-12 pt-1">
                        <p style={{ fontWeight: 600, opacity: 0.4 }}>Personal Email</p>
                        <div className='pt-1 d-flex justify-content-between'>
                          <p style={{ fontWeight: 700 }}>{data?.email}</p>
                          {data?.status !== "Pending"?  (data?.approval?.email  === true? <p className='text-success'>Accepted</p>: <p className='text-danger'>Rejected</p> ):""}
                          {data?.status === "Pending" &&
                          <div className='d-flex'>

                            <p  className='px-1' style={{opacity:0.4}}>Select To accept</p>
                            <Form.Check
                              type="checkbox"
                              id={`checkbox-${data.registarntEmail}`}
                              label=""
                              className="ml-2"
                              checked={fieldsVisible['registarntEmail'] || false}
                              onChange={() => handleCheckboxChange('registarntEmail')}
                            />
                          </div>
                    }
                        </div>
                      </div>
                     )}
                    {data.registrant_work_email?.length > 0 && (
                      <div className="col-12 col-sm-12 pt-1">
                        <p style={{ fontWeight: 600, opacity: 0.4 }}>Work Email</p>
                        <div className='pt-1 d-flex justify-content-between'>
                          <p style={{ fontWeight: 700 }}>{data?.registrant_work_email}</p>
                          {data?.status !== "Pending"?  (data?.approval?.registrant_work_email  === true? <p className='text-success'>Accepted</p>: <p className='text-danger'>Rejected</p> ):""}
                          {data?.status === "Pending" &&
                          <div className='d-flex'>

                            <p  className='px-1' style={{opacity:0.4}}>Select To accept</p>
                            <Form.Check
                              type="checkbox"
                              id={`checkbox-${data.registrantWorkEmail}`}
                              label=""
                              className="ml-2"
                              checked={fieldsVisible['registrantWorkEmail'] || false}
                              onChange={() => handleCheckboxChange('registrantWorkEmail')}
                            />
                          </div>
                    }
                        </div>
                      </div>
                     )} 

                    {data.phone?.length > 0 && (
                      <div className="col-12 col-sm-12 pt-1">
                        <p style={{ fontWeight: 600, opacity: 0.4 }}>Phone Number</p>
                        <div className='pt-1 d-flex justify-content-between'>
                          <p style={{ fontWeight: 700 }}>{data.phone}</p>
                          {data?.status !== "Pending"?  (data?.approval?.phone  === true? <p className='text-success'>Accepted</p>: <p className='text-danger'>Rejected</p> ):""}
                          {data?.status === "Pending" &&
                          <div className='d-flex'>

                            <p  className='px-1'  style={{opacity:0.4}}>Select To accept</p>
                            <Form.Check
                              type="checkbox"
                              id={`checkbox-${data.phoneNumber}`}
                              label=""
                              className="ml-2"
                              checked={fieldsVisible['phoneNumber'] || false}
                              onChange={() => handleCheckboxChange('phoneNumber')}
                            />
                          </div>
}
                        </div>
                      </div>
                    )}


                    {data.linkedin_link?.length > 0 && (
                      <div className="col-12 col-sm-12 pt-1">
                        <p style={{ fontWeight: 600, opacity: 0.4 }}>LinkedIn Link</p>
                        <div className='pt-1 d-flex justify-content-between'>
                          <p className="text-truncate" style={{ maxWidth: '200px', cursor: 'pointer' }} >
                            <a href={data.linkedin_link} target="_blank" rel="noopener noreferrer">{data.linkedin_link}</a>
                          </p>
                          {data?.status !== "Pending" ? (data?.approval?.linkedin_link === true ?
                            <p className='text-success'>Accepted</p> : <p className='text-danger'>Rejected</p>) : ""}
                          {data?.status === "Pending" &&
                            <div className='d-flex'>

                              <p className='px-1' style={{ opacity: 0.4 }}>Select To accept</p>
                              <Form.Check
                                type="checkbox"
                                id={`checkbox-${data.linkedinLink}`}
                                label=""
                                className="ml-2"
                                checked={fieldsVisible['linkedinLink'] || false}
                                onChange={() => handleCheckboxChange('linkedinLink')}
                              />
                            </div>
                          }
                        </div>
                      </div>
                    )}

                    {data.profile_image?.length > 0 && (
                      <div className="col-12 col-sm-12 pt-1">
                        <p style={{ fontWeight: 600, opacity: 0.4 }}>Registered Individual Image</p>
                        <div className='pt-1 d-flex  justify-content-between'>

                        <p style={{ fontWeight: 700 }}>
                            {data?.profile_image?.length > 0 &&
                              <img style={{ height: '100px', width: '100px' }} src={data.profile_image} alt=" profile_image" />
                            } </p>
                            {data?.status !== "Pending"?  (data?.approval?.profile_image  === true? <p className='text-success'>Accepted</p>: <p className='text-danger'>Rejected</p> ):""}
                          {data?.status === "Pending" &&
                          <div className='d-flex'>

                            <p  className='px-1' style={{opacity:0.4}}>Select To accept</p>
                            <Form.Check
                              type="checkbox"
                              id={`checkbox-${data.profile_image}`}
                              label=""
                              className="ml-2"
                              checked={fieldsVisible['profileImage'] || false}
                              onChange={() => handleCheckboxChange('profileImage')}
                            />
                          </div>
}
                        </div>
                      </div>
                    )}

                     {data.firm_location !== null && (<>


                     {data.country?.length>0 && data.province?.length &&
                          <div className='d-flex justify-content-between'>
                            <div>
                            <p style={{ fontWeight: 600, opacity: 0.4 }}>Address</p> 
                            </div>
                            {data?.status !== "Pending"?  (data?.approval?.address  === true? <p className='text-success'>Accepted</p>: <p className='text-danger'>Rejected</p> ):""}
                        {data?.status === "Pending" &&
                            <div className='d-flex'>
                            <p className='px-1' style={{ opacity: 0.4 }}>Select To accept</p>
                            <Form.Check
                              type="checkbox"
                              id={`checkbox-${data.city}`}
                              label=""
                              className="ml-2"
                              checked={fieldsVisible['address'] || false}
                              onChange={() => handleCheckboxChange('address')}
                            />
                            </div>
                        }

                            
                          </div>
                     }


                      <div className="col-12 col-sm-12 pt-1" style={{margin:"0rem 0rem 0rem 1rem"}}>
                        <Form.Group className="mb-3" controlId="locationCheckbox">
                            <>
                            <div>{
                              data.country?.length>0 && 
                              <>
                                <p style={{ fontWeight: 600, opacity: 0.4 }}>Country</p>
                                <div className='d-flex justify-content-between'>
                                  <p style={{ fontWeight: 700 }}>{data.country}</p>
                                  {/* {data?.status === "Pending" &&
                                  <div className='d-flex'>

                                    <p  className='px-1' style={{opacity:0.4}}>Select To accept</p>
                                    <Form.Check
                                      type="checkbox"
                                      id={`checkbox-${data.country}`}
                                      label=""
                                      className="ml-2"
                                      checked={fieldsVisible['country'] || false}
                                      onChange={() => handleCheckboxChange('country')}
                                    />
                                  </div>
} */}
                                </div>
                              </>
                              }
                            </div>
                            <div>{data.province?.length>0 && 
                              <>
                              <p style={{fontWeight:600,opacity:0.4}}>Provience</p>
                                <div className='d-flex justify-content-between'>
                                  <p style={{ fontWeight: 700 }}>{data.province}</p>

                                  {/* {data?.status === "Pending" &&
                                  <div className='d-flex'>

                                    <p  className='px-1' style={{opacity:0.4}}>Select To accept</p>
                                    <Form.Check
                                      type="checkbox"
                                      id={`checkbox-${data.province}`}
                                      label=""
                                      className="ml-2"
                                      checked={fieldsVisible['province'] || false}
                                      onChange={() => handleCheckboxChange('province')}
                                    />
                                  </div>
                                   } */}
                                </div>

                              </>
                              }
                            </div>

                            <div>{
                              data.city?.length>0 && 
                              <>
                              <p style={{fontWeight:600,opacity:0.4}}>City</p>
                              <div className='d-flex justify-content-between'>

                             
                            <p style={{fontWeight:700}}>{data.city}</p>
                            {/* {data?.status === "Pending" &&
                                <div className='d-flex'>

                                  <p  className='px-1' style={{opacity:0.4}}>Select To accept</p>
                                  <Form.Check
                                    type="checkbox"
                                    id={`checkbox-${data.city}`}
                                    label=""
                                    className="ml-2"
                                    checked={fieldsVisible['city'] || false}
                                    onChange={() => handleCheckboxChange('city')}
                                  />
                                </div>
                                } */}
                                </div>
                              </>
                            }
                            </div>

                            <div>{
                              data?.postal_code?.length>0 && 
                              <>
                                <p style={{ fontWeight: 600, opacity: 0.4 }}>Postal Code</p>
                                <div className='d-flex justify-content-between'>
                                  <p style={{ fontWeight: 700 }}>{data?.postal_code}</p>
                                  {/* {data?.status === "Pending" &&
                                  <div className='d-flex'>

                                    <p  className='px-1' style={{opacity:0.4}}>Select To accept</p>
                                    <Form.Check
                                      type="checkbox"
                                      id={`checkbox-${data.postalCode}`}
                                      label=""
                                      className="ml-2"
                                      checked={fieldsVisible['postalCode'] || false}
                                      onChange={() => handleCheckboxChange('postalCode')}
                                    />
                                  </div>
                                  } */}
                                </div>
                              </>
                            }
                            </div>

                            <div>{
                              data.location?.length>0 && 
                              <>
                                <p style={{ fontWeight: 600, opacity: 0.4 }}>Location</p>
                                <div className='d-flex justify-content-between'>
                                  <p style={{ fontWeight: 700 }}>{data.location}</p>
                                  {/* {data?.status === "Pending" &&
                                  <div className='d-flex'>

                                    <p  className='px-1' style={{opacity:0.4}}>Select To accept</p>
                                    <Form.Check
                                      type="checkbox"
                                      id={`checkbox-${data.location}`}
                                      label=""
                                      className="ml-2"
                                      checked={fieldsVisible['location'] || false}
                                      onChange={() => handleCheckboxChange('location')}
                                    />
                                  </div>
                                    } */}
                                </div>
                              </>
                            }
                            </div>
                            </>
                         
                        </Form.Group>
                      </div></>
                    )}


                  


                    <div>{data.current_company_name?.length > 0 &&
                      <>
                        <p style={{ fontWeight: 600, opacity: 0.4 }}>Company Name</p>
                        <div className='d-flex justify-content-between'>
                          <p style={{ fontWeight: 700 }}>{data.current_company_name}</p>
                          {data?.status !== "Pending"?  (data?.approval?.current_company_name  === true? <p className='text-success'>Accepted</p>: <p className='text-danger'>Rejected</p> ):""}
                          {data?.status === "Pending" &&
                            <div className='d-flex'>

                              <p className='px-1' style={{ opacity: 0.4 }}>Select To accept</p>
                              <Form.Check
                                type="checkbox"
                                id={`checkbox-${data.current_company_name}`}
                                label=""
                                className="ml-2"
                                checked={fieldsVisible['current_company_name'] || false}
                                onChange={() => handleCheckboxChange('current_company_name')}
                              />
                            </div>
                          }
                        </div>

                      </>
                    }
                    </div>

                    <div>{data.current_job_title?.length > 0 &&
                      <>
                        <p style={{ fontWeight: 600, opacity: 0.4 }}>Company Job Title</p>
                        <div className='d-flex justify-content-between'>
                          <p style={{ fontWeight: 700 }}>{data.current_job_title}</p>
                          {data?.status !== "Pending"?  (data?.approval?.current_job_title  === true? <p className='text-success'>Accepted</p>: <p className='text-danger'>Rejected</p> ):""}
                          {data?.status === "Pending" &&
                            <div className='d-flex'>

                              <p className='px-1' style={{ opacity: 0.4 }}>Select To accept</p>
                              <Form.Check
                                type="checkbox"
                                id={`checkbox-${data.current_job_title}`}
                                label=""
                                className="ml-2"
                                checked={fieldsVisible['current_job_title'] || false}
                                onChange={() => handleCheckboxChange('current_job_title')}
                              />
                            </div>
                          }
                        </div>

                      </>
                    }
                    </div>

                    <div>{data.company_industry?.length > 0 &&
                      <>
                        <p style={{ fontWeight: 600, opacity: 0.4 }}>Company Industry</p>
                        <div className='d-flex justify-content-between'>
                          <p style={{ fontWeight: 700 }}>{data.company_industry}</p>
                          {data?.status !== "Pending"?  (data?.approval?.company_industry  === true? <p className='text-success'>Accepted</p>: <p className='text-danger'>Rejected</p> ):""}
                          {data?.status === "Pending" &&
                            <div className='d-flex'>

                              <p className='px-1' style={{ opacity: 0.4 }}>Select To accept</p>
                              <Form.Check
                                type="checkbox"
                                id={`checkbox-${data.company_industry}`}
                                label=""
                                className="ml-2"
                                checked={fieldsVisible['company_industry'] || false}
                                onChange={() => handleCheckboxChange('company_industry')}
                              />
                            </div>
                          }
                        </div>

                      </>
                    }
                    </div>


                    <div>{data.company_linkedin_link?.length > 0 &&
                      <>
                        <p style={{ fontWeight: 600, opacity: 0.4 }}>Company LinkedIn List</p>
                        <div className='d-flex justify-content-between'>
                          <p style={{ fontWeight: 700 }}>{data.company_linkedin_link}</p>
                          {data?.status !== "Pending"?  (data?.approval?.company_linkedin_link  === true? <p className='text-success'>Accepted</p>: <p className='text-danger'>Rejected</p> ):""}
                          {data?.status === "Pending" &&
                            <div className='d-flex'>

                              <p className='px-1' style={{ opacity: 0.4 }}>Select To accept</p>
                              <Form.Check
                                type="checkbox"
                                id={`checkbox-${data.company_linkedin_link}`}
                                label=""
                                className="ml-2"
                                checked={fieldsVisible['company_linkedin_link'] || false}
                                onChange={() => handleCheckboxChange('company_linkedin_link')}
                              />
                            </div>
                          }
                        </div>

                      </>
                    }
                    </div>



                    <div className="col-12 col-sm-12 pt-1 ">
                      {data.others?.length > 0 && (
                        <div className='pt-1'>
                          <p style={{ fontWeight: 600, opacity: 0.4 }}>Others</p>
                          <div className='d-flex justify-content-between'>
                            <p style={{ fontWeight: 700 }}>{data.others}</p>
                            {data?.status !== "Pending"?  (data?.approval?.others  === true? <p className='text-success'>Accepted</p>: <p className='text-danger'>Rejected</p> ):""}
                            {data?.status === "Pending" &&
                            <div className='d-flex'>

                              <p  className='px-1' style={{opacity:0.4}}>Select To accept</p>
                              <Form.Check
                                type="checkbox"
                                id={`checkbox-${data.others}`}
                                label=""
                                className="ml-2"
                                checked={fieldsVisible['otherChecked'] || false}
                                onChange={() => handleCheckboxChange('otherChecked')}
                              />
                            </div>
}
                          </div>
                        </div>
                      )}
                    </div>


                      {data?.status === "Pending" &&
                      <>
                      <svg xmlns="http://www.w3.org/2000/svg" width="502" height="2" viewBox="0 0 502 2" fill="none">
                        <path d="M1 1L501 0.999956" stroke="#E1DEDE" strokeWidth="1.5" strokeLinecap="round" />
                      </svg>


                    <div className='d-flex justify-content-between'>
                      {data.others?.length > 0 ? (
                        <p style={{ fontSize: 14, opacity: 0.7 }}>
                         <span style={{ color: 'red' }}>**</span>Need to update manually by Admin to accept flags.  
                         </p>):<span></span> }  
                      {/* <p style={{ fontSize: 14, opacity: 0.7 }}> <span style={{ color: 'red' }}>**</span>Need to update manually by Admin to accept flags. </p> */}
                       {!isAnyFieldVisible() ? 
   <p style={{ color: '#11AB0E', fontSize: '13px', textDecoration: 'underline', cursor: 'pointer' }} onClick={acceptAll}> Select All </p> : 
   <p style={{ color: 'red', fontSize: '13px', textDecoration: 'underline', cursor: 'pointer' }} onClick={rejectAll}> Deselect All </p> }

                    </div>
                    </>
                         } 

                    
                    {data?.status === "Pending" &&
                      <div className="col-12 col-sm-12">
                        <Form.Group className="mb-3" controlId="feedback">
                          <Form.Label style={{ fontWeight: 600 }}>Feedback</Form.Label>
                          <div className="d-flex align-items-center pt-1">
                            <Form.Control
                              as="textarea"
                              value={data.flagReason}
                              name="feedback"
                              placeholder="Enter Feedback"
                              onChange={handleInputChange}
                              style={{ flex: 1 }}
                            />

                          </div>

                        </Form.Group>
                      </div>
                          }


                      {data?.status === "Pending" &&
              <div className="d-flex justify-content-start align-items-center">
              {
                !isAnyFieldVisible() ?  
                <Button
                className="me-2"
                // onClick={handleSubmit}
                style={{
                  borderRadius: '4px',
                  // background: 'linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)',
                  // cursor:'default'

                  background: 'linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)',
                  opacity: 0.6 ,
                  cursor:  'not-allowed' 
                }}
                disabled={disable}
              >
                Submit 
              </Button>
              :
              <Button
              className="me-2"
              onClick={handleSubmit}
              style={{
                borderRadius: '4px',
                background: 'linear-gradient(90deg, #0F66B4 0%, #147FDE 100%)'
              }}
              disabled={disable}
            >
              Submit 
            </Button>
              }
             
             {
              !isAnyFieldVisible() ? 

              <Button
                className="me-2"
                onClick={handleSubmit}
                style={{
                  borderRadius: '4px',
                  background: 'red',
                  border:'0px solid '
                }}
                disabled={disable}
              >Reject</Button>

              :

              <Button
                className="me-2"
                // onClick={handleSubmit}
                style={{
                  borderRadius: '4px',
                  // background: 'red',
                  border:'0px solid ',
                  // cursor:'default'
                  background: 'red',
                  opacity: 0.6 ,
                  cursor:  'not-allowed' 
                }}
                disabled={disable}
              >Reject</Button>
             }
              
              {/* <p style={{ color: 'red', fontSize: '13px', textDecoration: 'underline', cursor: 'pointer' }} >Reject</p> */}
                {/* {
                  !isAnyFieldVisible() ? "hi" : 'hello'
                } */}

            </div>
              }
                    </div>



                  ))
                  }



                </div>


              </Modal.Body>
            </Modal>     
        </>
    );
 };
export default RegistrantFlagModal;