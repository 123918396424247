import React, { useState, useEffect, useRef, useCallback } from "react";
import { Container, Row, Col, Modal } from "react-bootstrap";
import Select from "react-select";
import axios from "axios";
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import { useNavigate, Link } from "react-router-dom";
import Footer from '../components/footer/Footer';
import { BallTriangle } from 'react-loader-spinner';
import { useDispatch, useSelector } from 'react-redux';
import toast from "react-hot-toast";
import { fetchFirms } from '../redux/slice/firm';
import Firm from "./home/Firm";
import CustomPagination from "../components/pagination/CustomPagination";
import 'rc-slider/assets/index.css';
import Slider from 'rc-slider';
import { fetchCategory } from './../redux/slice/category';
import { set } from 'lodash';


const User = (props) => {

const [emptyState, setEmptyState] = useState(false);
  const logindata = JSON.parse(window.localStorage.getItem("login"));
  // const categoryState = useSelector((state) => state.category);

  const inputRef= useRef(null);
  const capitalizeFirstLetter = (str) => { return str?.charAt(0)?.toUpperCase() + str?.slice(1); };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const PAGE_SIZE = 10;

  useEffect(() => {
    document.title = "PCMA || Registered Firms Dashboard";
  }, []);

  let userName;
  let token;
  let localUserId;
  let paginatedOptions;
  let bookMarkuserId = logindata?.user_data?._id;
  let totalPages = '';
  let dataArray = [];
  const dropdownRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState(window.history.state?.searchTerm || props?.search || '');
  const mapRef = useRef(null);
  const [autocompleteSearch, setAutocompleteSearch] = useState(window.history.state?.autocompleteSearch || '');
  // const [longitude, setLongitude] = useState(window.history?.longitude || null);
  // const [latitude, setLatitude] = useState(window.history?.latitude || null);
  const [radius, setRadius] = useState(window.history.state?.radius || 0);
  const [selectedCategory, setSelectedCategory] = useState(window.history.state?.selectedCategory || '');
  const [isOnlyCategory, setIsOnlyCategory] = useState(window.history.state?.isOnlyCategory || 1);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isHeadOffice, setIsHeadOffice] = useState(window.history.state?.isHeadOffice || 0);
  const [noData, setNoData] = useState('');
  const [disable, setDisable] = useState(true);
  const [disableDownloadCsv, setDisableDownloadCsv] = useState(false);
  const [userAddress, setUserAddress] = useState('')
  const [bookmarkData, setbookmarkData] = useState([]);
  const [noticeDatas, setNoticeData] = useState([])
  const [mainBookmarkId, setMainBookmarkId] = useState([]);
  const [bookmarkLength, setbookmarkLength] = useState();
  const [latlngData, setLatlngData] = useState([]);
  const [countryData, setCountryData] = useState();
  const [provinceData, setProvinceData] = useState();
  const [cityData, setCityData] = useState();
  const [latitudes, setLatitudes] = useState([]);
  const [longitudes, setLongitudes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [infoWindowPosition, setInfoWindowPosition] = useState(null);
  const [firmOption, setFirmOption] = useState([]);
  const [postalCode, setPostalCode] = useState([]);
  const [companySizeOption, setCompanySizeOption] = useState([]);
  const [regionOption, setRegionOption] = useState([]);
  const [registantOption, setRegistantOption] = useState([]);
  const [total, setTotal] = useState('');
  const [data, setData] = useState([]);
  const [goToPage, setGoTOPage] = useState(props?.currentPage);
  const [newtGoTOPage, setNewtGoTOPage] = useState(null);
  const [allOptions, setAllOptions] = useState([]);
  const [prevOptions, setPrevOptions] = useState([]);
  const [prevPaginatedOptions, setPrevPaginatedOptions] = useState([]);
  const [map, setMap] = useState(null);
  const [totalData, setTotalData] = useState('');
  const [isHovered, setIsHovered] = useState(false);
  const [hoveredMarkerIndex, setHoveredMarkerIndex] = useState(null);
  const [infoWindowVisible, setInfoWindowVisible] = useState(false);
  const prevPage = useRef(null);
  const [d, setD] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [notificationName, setNotificationName] = useState('');
  const [notificationDate, setNotificationDate] = useState('');
  const [notificationmessage, setNotificationmessage] = useState('');
  const render = useRef(0);
  const [profileImage, setProfileImage] = useState('');
  const [onfocas, placeholderDataHide] = useState(false);
  const [gotopag, setGotopag] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedMarkar, setSelectedMarkar] = useState();
  const [selectedAddress, setSelectedAddress] = useState(window.history.state?.selectedAddress || "Province");
  const dropdownOptions = ["Province", "Head Office", "Near Location"];
  // const center = {
  //   lat:43.6532,
  //   lng: 79.3832,
  // };
  const center = {
    lat: null,
    lng: null,
  };
  const [country, setCountry] = useState('');
  const [province, setProvince] = useState(window.history?.province || '');
  const [city, setCity] = useState(window.history?.city || '');
  const [marker, setMarker] = useState(center);
  const [latLng, setLatLng] = useState(window.history.state?.latLng || center);
  const [address, setAddress] = useState({
    location: '',
    city: '',
    state: '',
    country: '',
    postalCode: '',
  });
  var labelValue = '', labelValue1 = '', labelValue2 = '', labelValue3 = '', labelValue4 = "";

  if (logindata) { token = logindata.token; }
  else { token = null; }


  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  if (logindata?.user_data?.name) { userName = logindata?.user_data?.name; }
  if (logindata?.user_data?._id) { localUserId = logindata?.user_data?._id; }



  const [latLocation, setLatLocation] = useState('');
  const [longLocation, setLongLocation] = useState('');
  const [location, setLocation] = useState({
    city: '',
    region: '',
    country: '',
    latitude: null,
    longitude: null,
  });

  const getLocations = async () => {
    try {
      const response = await fetch('https://ipinfo.io/json');
      if (!response.ok) {
        throw new Error('Failed to fetch location');
      }
      const data = await response.json();
      const { city, region, country, loc } = data;
      const [latitude, longitude] = loc.split(',');
      setLocation({
        city,
        region,
        country,
        latitude,
        longitude
      });
      setLatLocation(latitude);
      setLongLocation(longitude);

      const datas = { lat: latitude, lng: longitude };
      localStorage.setItem('userlocation', JSON.stringify(datas))
    } catch (error) {
      console.error('Error fetching location:', error);
      setLocation({
        city: 'Location unavailable',
        region: '',
        country: '',
        latitude: '',
        longitude: ''
      });
    }
  };

  useEffect(() => {
    getLocations();
  }, []);

  // const handleNearMe = () => {
  //   navigate('/firm/nearby')
  // }


  const formatNoticeData = (dateString) => {
    const date = new Date(dateString);
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(yesterday.getDate() - 1);

    if (date.toDateString() === today.toDateString()) {
      return 'Today';
    } else if (date.toDateString() === yesterday.toDateString()) {
      return 'Yesterday';
    } else {
      return date.getDate() + (date.getDate() % 10 === 1 && date.getDate() !== 11 ? 'st' : date.getDate() % 10 === 2 && date.getDate() !== 12 ? 'nd' : date.getDate() % 10 === 3 && date.getDate() !== 13 ? 'rd' : 'th') + ' ' + date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' });
    }
  };

  const handleResetSearch = () => {
    if (searchTerm.length > 0) {
      setSearchTerm('');
      props?.setSearch('');
      props?.setCurrentPage(1);
    }
  };

  // handle ip of user 
  const handlePageChange = (data) => {
    props?.setCurrentPage(data);
    setGoTOPage(data);
    window.scrollTo(0, 0);
    setNewtGoTOPage(data);
  }

  const handlePageSearch = (val) => {
    if (gotopag === true) {

      const inputPage = parseInt(val);
      if ((props?.currentPage == val) || val == null) {
        if (props?.currentPage >= totalPages) {
          toast.error(`Page ${props?.currentPage + 1} does not exist`)
        }
        else {
          props?.setCurrentPage(props?.currentPage + 1);
          setGoTOPage(props?.currentPage + 1);
          window.scrollTo(0, 0);
          setNewtGoTOPage(props?.currentPage + 1)
        }
      }
      else {
        if (!isNaN(inputPage) && inputPage > 0 && inputPage <= totalPages) {
          props?.setCurrentPage(inputPage);
          window.scrollTo(0, 0);
          setGoTOPage(inputPage);
        } else if (inputPage >= totalPages) {
          toast.error(`Page ${inputPage} does not exist`);

        }
        placeholderDataHide(false)
      }
      setGotopag(false);
    }
  };

  // useEffect(() => {
  //   if (categoryState) {
  //     setFirmOption(categoryState?.data?.firms);
  //     setPostalCode(categoryState?.data?.postal_code);
  //     setCompanySizeOption(categoryState?.data?.Company_Size);
  //     setRegionOption(categoryState?.data?.dealing_representative);
  //     setRegistantOption(categoryState?.data?.province);
  //   }
  // }, [categoryState]);

  useEffect(() => {
    redirectToLogin();
  }, []);

  useEffect(() => {
    locData();
    getLocation(dataArray);
  }, [latitudes, longitudes]);

  useEffect(() => {
    setInfoWindowVisible(true);
  }, []);

  const options = regionOption?.filter(Boolean)?.map(option => ({ value: option, label: option }));




  const defaultValue = options;

  const [selectedOption1, setSelectedOption1] = useState(window.history.state?.selectedOption1 || defaultValue);
  const [selectedOption2, setSelectedOption2] = useState(window.history.state?.selectedOption2 || '');
  const [selectedOption3, setSelectedOption3] = useState(window.history.state?.selectedOption3 || "");
  const [selectedOption4, setSelectedOption4] = useState(window.history.state?.selectedOption4 || '');
  // const [selectedOption5, setSelectedOption5] = useState(window.history.state?.selectedOption5 || defaultValue4);



  const [selectedOption, setSelectedOption] = useState(window.history.state?.selectedOption || null);
  const [currentPages, setCurrentPages] = useState(1);
  const startIndex = (currentPages - 1) * PAGE_SIZE;
  const endIndex = currentPages * PAGE_SIZE;

  paginatedOptions = options?.slice(startIndex, endIndex);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
    // setSelectedOption5('');
    props?.setCurrentPage(1);
  };

  useEffect(() => {
    const newHistoryState = {
      searchTerm,
      selectedAddress,
      autocompleteSearch,
      selectedCategory,
      radius,
      isHeadOffice,
      isOnlyCategory,
      latLng
    };
    window.history.replaceState(newHistoryState, '');
  }, [searchTerm, selectedAddress, autocompleteSearch, latLng, isHeadOffice, isOnlyCategory, selectedCategory, radius]);

  const handleMenuScroll = () => {
    const totalOptions = options?.length;
    const totalPagess = Math?.ceil(totalOptions / PAGE_SIZE);
    if (currentPages < totalPagess) {
      setPrevOptions(allOptions);
      setPrevPaginatedOptions((prevOptions) => [...prevOptions, ...paginatedOptions]);
      setCurrentPages((prevPage) => prevPage + 1);
    }
  };


  const logout = () => {
    localStorage.removeItem('login');
    toast.success('You have logged out successfully.');
    navigate("/login");

  }



  const redirectToLogin = () => {
    token = logindata?.token || null;
    if (token === null) { navigate('/login'); }
  };

  const locData = () => {
    if (latitudes?.length > 0 && longitudes?.length > 0 && latitudes?.length === longitudes?.length) {
      for (let i = 0; i < latitudes?.length; i++) {
        dataArray.push({ latitude: latitudes[i], longitude: longitudes[i], });
      }
    }
  };

  const getLocation = (locations) => {
    if (locations?.length === 0) { return; }
    const locationData = locations.map((location) => {
      const { latitude, longitude } = location;
      return { lat: latitude, lng: longitude, };
    });
    setLatlngData(locationData);
  };

  const handleMarkerHover = (index) => {
    setInfoWindowPosition({ lat: parseFloat(latlngData[index]?.lat), lng: parseFloat(latlngData[index]?.lng) });
    setHoveredMarkerIndex(index);
    setIsHovered(true);
  };

  const handleInfoWindowClose = () => {
    setIsHovered(false);
    setHoveredMarkerIndex(null);
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        cursor: isFocused ? "pointer" : null,
      };
    }
  };

  // useEffect(() => {
  //   if (logindata?.token) {
  //     axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/country`, {
  //       headers: {
  //         Authorization: `Bearer ${logindata?.token}`,
  //       },
  //     }).then((res) => {
  //       const options = res?.data?.map(province => ({
  //         value: province._id,
  //         label: province.name
  //       }));
  //       setCountryData(options);
  //       if (selectedOption2?.label?.length > 0) {
  //         stateApi(selectedOption1);
  //       }
  //     }).catch((error) => {
  //       console.error('Error fetching province data:', error);
  //       toast.error('Error fetching province data');
  //     });
  //   }
  // }, []);

  const [checkBookMark, setCheckBookMark] = useState(() => {
    const savedBookmarks = localStorage.getItem('bookmarks');
    return (savedBookmarks && savedBookmarks !== "undefined") ? JSON.parse(savedBookmarks) : [];

  });


  useEffect(() => {
    fetchData();
  }, [dispatch, selectedOption1?.value, selectedOption2?.value, checkBookMark]);


  const handleSearch = (data) => {
    props?.setCurrentPage(1);
    setSearchTerm(data);
  };

  const handleAddressSelect = (option) => {
    // setAutocompleteSearch('');
    // setAddress({
    //   location: '',
    //   city: '',
    //   state: '',
    //   country: '',
    //   postalCode: '',
    // });
    // setLatLng(center);
    setSelectedAddress(option);
    setIsHeadOffice(option === "Head Office" ? 1 : 0);
    setRadius(option !== 'Near Location' ? 0 : 500);
    props?.setCurrentPage(1)
  };

  const handleDistanceChange = (value) => {
    setRadius(value);
    props?.setCurrentPage(1)
  };

  const handleCategoryChange = (e) => {
    setIsOnlyCategory(Number(e.target.value));
  };

  const handleSelectOption = (category) => {
    setSelectedCategory(category);
    if (dropdownRef.current) {
      const dropdown = new window.bootstrap.Dropdown(dropdownRef.current);
      dropdown.hide();
  }
  };

  // const handleSearchAddressChange = (e) => {
  //   const value = e.target.value;
  //   onPlaceChanged(value);
  //   setAutocompleteSearch(value);
  //   window.history.replaceState(
  //     { ...window.history.state, autocompleteSearch: value },
  //     ''
  //   );
  // };

  const firms = useSelector((state) => state.firms);

  const { lat, lng } = latLng || {};

  const api = async (a) => {
    // var pagin;
    // if (a) {

    //   pagin = props?.currentPage;
    //   setGoTOPage(props?.currentPage)
    //   // props?.setCurrentPage(1);
    // } else {

    //   pagin = props?.currentPage;
    // }
    emptyState ? props?.setCurrentPage(1) : props?.setCurrentPage(props?.currentPage)
    try {
      setIsLoading(true)
      const data = await dispatch(fetchFirms({
        page: emptyState? 1 : props?.currentPage,
        search: searchTerm,
        country: inputRef?.current?.value!== "" ? address?.country : "",
        province: inputRef?.current?.value!== "" ? address?.state : "",
        city:  inputRef?.current?.value!== "" ? address?.city : "",
        location:  inputRef?.current?.value!== "" ? address?.location : "",
        latitude: inputRef?.current?.value!== "" ? lat : "",
        longitude: inputRef?.current?.value!== "" ? lng : "",
        category: selectedCategory,
        is_head_office: isHeadOffice,
        is_only_category: isOnlyCategory,
        "radius": radius,
      }
        ,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }));
      if (data?.payload.response?.data?.message === "Unauthozied user") {
        localStorage.removeItem('login');
        navigate("/");
        // toast.error(`Unauthozied user`);
      } else if (data?.payload.response?.data?.message === "Too Many Attempts.") {
        toast.error(`Too Many Attempts, Please Try Again Later.`);
        setIsLoading(true);
      } else if (data?.payload.response?.data?.message == 'No data found') {
        setNoData('No data found');
        setDisable(false);
      } else {
        setIsLoading(false);
      }
      // address?.country?.length > 0 || address?.state?.length > 0 || address?.city?.length > 0
      if (searchTerm > 0 || autocompleteSearch?.length > 0 || selectedCategory?.length > 0) {
        setDisable(false);
        if (data?.payload.response?.data?.message == 'No data found') {
          setNoData('No data found');
          setDisable(false);
          setDisableDownloadCsv(false);
        } else {
          setNoData('')
        }

      } else {
        setDisableDownloadCsv(false);
        if (data?.payload.response?.data?.message == 'No data found') {
          setDisable(false);
          setNoData('No data found');
        } else {
          setDisable(true);
          setNoData('');
        }

      }
      // if (autocompleteSearch.length === 0 || selectedCategory.length === 0) {
      //   console.log('1');

      //   setNoData("");
      //   setDisable(true);
      //   setDisableDownloadCsv(false);
      // }
      // else if (autocompleteSearch.length > 0 || selectedCategory.length > 0) {
      //   console.log('2');
      //   setNoData("");
      //   setDisable(false);
      //   setDisableDownloadCsv(false);
      // }
      // else if (autocompleteSearch.length > 0 && selectedCategory.length === 0) {
      //   console.log('3');
      //   setNoData("");
      //   setDisable(false);
      //   setDisableDownloadCsv(false);
      // }
      // else if (selectedCategory.length > 0) {
      //   console.log('4');
      //   setNoData("");
      //   setDisable(false);
      //   setDisableDownloadCsv(false);
      // }
      // else if (data?.payload.response?.data?.message === "No data found" && (selectedCategory.length > 0)) {
      //   console.log('5');
      //   setNoData("No data found");
      //   setDisable(false);
      // }
      // else if (data?.payload.response?.data?.message === "No data found") {
      //   console.log('6');
      //   setNoData("No data found");
      //   setDisable(false);
      //   setDisableDownloadCsv(true);
      // }
      // else if (data?.payload.response?.data?.message === "No data found") {
      //   console.log('7');
      //   setNoData("No data found");
      //   setDisable(true);
      //   setDisableDownloadCsv(true);
      // }
      // else if (selectedCategory?.length > 0) {
      //   console.log('8');
      //   setDisable(false);
      //   setNoData("");
      // }
      // else if (searchTerm?.length > 0) {
      //   console.log('9');
      //   setDisable(false);
      //   setNoData("");
      // }
      // else {
      //   console.log('10');
      //   setNoData("");
      //   setDisable(true);
      //   setDisableDownloadCsv(false);
      // }
      setData(data?.payload?.data)
      setTotal(data?.payload?.last_page)
      setTotalData(data?.payload?.total);
      const latitudes = data?.payload?.data?.map(item => item.latitude || 0);
      const longitudes = data?.payload?.data?.map(item => item.longitude || 0);
      setLatitudes(latitudes);
      setLongitudes(longitudes);
      props?.setCheckBack(false);
    }
    catch (error) {
    } finally {
      setIsLoading(false);
      setEmptyState(false);
    }
  };


  const downloadData = async () => {

    try {
      setIsDownloading(true);
      const response = await axios.get(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/firms/export`,
        {
          params: {
            // page: props?.currentPage,
            search: searchTerm,
            country: address?.country,
            province: address?.state,
            city: address?.city,
            // location: address?.location,
            latitude: latLng?.lat || null,
            longitude: latLng?.lng || null,
            category: selectedCategory,
            is_head_office: isHeadOffice,
            is_only_category: isOnlyCategory,
            "radius": radius,
            // country: labelValue2,
            // NRD: "",
            // page: props?.currentPage,
            // number_of_people: selectedOption4?.label,
            // search_value: searchTerm,
            // province: labelValue1,
            // registrant_name: labelValue3,
            // postal_code: labelValue4,
            // city: selectedOption?.label
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response?.data?.message === "Unauthorized user") {
        localStorage.removeItem('login');
        navigate("/");
        toast.error(`Unauthorized user`);
        return;
      }

      let csvResponseData = response?.data;

      if (csvResponseData && typeof csvResponseData === 'string') {
        // const rows = csvResponseData.split('\n');
        // const csvData = rows.map(row => row.split(',').map(cell => cell.replace(/"/g, '').trim()));

        const blob = new Blob([csvResponseData], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = 'Registered Firms Details.csv';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);

        // let csvResponseData = response?.data;
        // const blob = new Blob([csvResponseData], { type: 'text/csv;charset=utf-8;' });
        // const link = document.createElement('a');
        // link.href = URL.createObjectURL(blob);
        // link.download = 'PCMA Registered Firms Details.csv';
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
      } else {
        console.warn('Data is not a string or is undefined.');
      }

      if (response.data) {
        setD(response?.data);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      if (error?.message === "Request failed with status code 401") {
        localStorage.removeItem('login');
        navigate("/");
        toast.error(`Unauthorized user!`);
      }
    } finally {
      setIsDownloading(false);
    }
  };




  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_API_KEY_AUTOCOMPLETE;
  const { isLoaded: isAutocompleteLoaded, loadError } = useLoadScript({
    googleMapsApiKey: googleMapsApiKey,
    libraries: ['places'],
  });

  // const onMapClick = useCallback((event) => {
  //   const newLatLng = {
  //     lat: event.latLng.lat(),
  //     lng: event.latLng.lng(),
  //   };
  //   setMarker(newLatLng);
  //   setLatLng(newLatLng);
  //   geocodeLatLng(newLatLng.lat, newLatLng.lng);
  // }, []);

  // const geocodeLatLng = (lat, lng) => {
  //   const geocoder = new window.google.maps.Geocoder();
  //   const latlng = { lat, lng };

  //   geocoder.geocode({ location: latlng }, (results, status) => {

  //     if (status === 'OK') {
  //       // if (results[5]) {
  //       //   const addressComponents = results[5]?.address_components;
  //       //   const location = results[5]?.formatted_address;

  //       //   let city = '';
  //       //   let state = '';
  //       //   let country = '';
  //       //   let postalCode = '';


  //       //   for (let component of addressComponents) {
  //       //     const types = component.types;
  //       //     if (types.includes('locality')) {
  //       //       city = component.long_name;
  //       //     }
  //       //     if (types.includes('administrative_area_level_1')) {
  //       //       state = component.long_name;
  //       //     }
  //       //     if (types.includes('country')) {
  //       //       country = component.long_name;
  //       //     }
  //       //     if (types.includes('postal_code')) {
  //       //       postalCode = component.long_name;
  //       //     }
  //       //   }

  //       //   setAddress({ location, city, state, country, postalCode });
  //       // }
  //     } else {
  //       toast.error('Geocoder failed due to: ' + status);
  //     }
  //   });
  // };

  useEffect(() => {
    // Retrieve state from history on page load
    const state = window.history.state;
    if (state) {
        setSelectedCategory(state.selectedCategory || '');
        setAutocompleteSearch(state.autocompleteSearch || '');
    }
}, []);



  const autocompleteRef = useRef(null);

  const onPlaceChanged = (value) => {
    const place = autocompleteRef?.current?.getPlace();
    console.error('Geocoder ', place);
    let country = '', state = '', city = '', location = '';
    if (place?.address_components != undefined && value!=="") {
      for (let element of place?.address_components) {

        if (element?.types[0] == 'country') {
          country = element?.long_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
          break;
        } else if (element?.types[0] == 'administrative_area_level_1') {
          state = element?.long_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        } else if (element?.types[0] == 'locality') {
          city = element?.long_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        } else if (element?.types[0] == 'sublocality_level_1') {
          location = element?.long_name?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
      }
    }
    props?.setCurrentPage(1);
    setGoTOPage(props.currentPage);
    setAddress({ location, city, state, country });
    setAutocompleteSearch(place?.formatted_address || "");
    if (place?.geometry) {
      const newLatLng = {
        lat: place?.geometry?.location.lat(),
        lng: place?.geometry?.location.lng(),
      };
      setMarker(newLatLng);
      value!=="" ? setLatLng(newLatLng) : setLatLng({
        lat: null,
        lng: null,
      });
      mapRef?.current?.panTo(newLatLng);
      // geocodeLatLng(newLatLng?.lat, newLatLng?.lng);
    }
  };

  // const onPlaceChanged = () => {
  //   const place = autocompleteRef.current.getPlace();

  //   console.error('place change', place.address_components)
  //   if (place.address_components != undefined) {
  //     setCountry('');
  //     setProvince('');
  //     setCity('');
  //     for (let element of place.address_components) {

  //       if (element.types[0] == 'country') {
  //         setCountry(element.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
  //         break;
  //       } else if (element.types[0] == 'administrative_area_level_1') {
  //         setProvince(element.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
  //       } else if (element.types[0] == 'locality') {
  //         setCity(element.long_name.normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
  //       }
  //     }

  //   }

  //   if (place.geometry) {
  //     const newLatLng = {
  //       lat: place.geometry.location.lat(),
  //       lng: place.geometry.location.lng(),
  //     };
  //     setMarker(newLatLng);
  //     setLatLng(newLatLng);
  //     mapRef.current.panTo(newLatLng);
  //     geocodeLatLng(newLatLng.lat, newLatLng.lng);
  //   }
  // };






  useEffect(() => {
    let timerOut = setTimeout(() => {
      if (inputRef?.current?.value !== "" && address?.state=="" && selectedAddress==="Province" && searchTerm === "") {
        toast.error('Please select a valid province');
      } else if(emptyState && inputRef?.current?.value === ""){
        api();
      }
      else if (inputRef?.current?.value !== ""){
        api()
      }
      else if(inputRef?.current?.value !== "" && (isOnlyCategory === 0 || isOnlyCategory === 1)){
        api()
      }
      else if(selectedCategory?.length !== 0){
        api()
      }
      else if( isHeadOffice === 1){
        api()
      }
      else if( isHeadOffice === 0){
        api()
      }
    }, 1600);
    return () => clearTimeout(timerOut)
  }, [emptyState, searchTerm, autocompleteRef?.current?.getPlace(), autocompleteSearch, selectedCategory, radius, isOnlyCategory, isHeadOffice])


  useEffect(() => {
    props?.currentPage >= 1 ? setTimeout(() => {
      setGoTOPage(props?.currentPage)
      api();
    }, 1000) : inputRef?.current?.value !== "" ?? setTimeout(() => {
      setGoTOPage(props?.currentPage)
      api();
    }, 1600);
  }, [props?.currentPage])

  totalPages = total;


  const handleChange1 = (selectedOption1) => {
    setSelectedOption1(selectedOption1);
    // setSelectedOption2('');
    setSelectedOption('');
    // CityApi(selectedOption1);
    props?.setCurrentPage(1);
    setGoTOPage(1);
  };
  const handleChange2 = (selectedOption2) => {
    setSelectedOption2(selectedOption2);
    setSelectedOption1('');
    setSelectedOption('');
    // stateApi(selectedOption2);
    props?.setCurrentPage(1);
    setGoTOPage(1);
  };

  // const stateApi = (value) => {
  //   if (logindata?.token) {
  //     axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/state`, {
  //       headers: {
  //         Authorization: `Bearer ${logindata?.token}`,
  //         'X-Id': value?.value,
  //       },
  //     }).then((res) => {
  //       const options1 = res?.data?.map(name => ({
  //         value: name._id,
  //         label: name.name
  //       }));
  //       setProvinceData(options1);

  //     }).catch((error) => {
  //       console.error('Error fetching province data:', error);
  //       toast.error('Error fetching province data');
  //     });
  //   }
  // };
  // const CityApi = (value) => {
  //   if (logindata?.token) {
  //     axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/city`, {
  //       headers: {
  //         Authorization: `Bearer ${logindata?.token}`,
  //         'X-Id': value.value,
  //       },
  //     }).then((res) => {
  //       const options1 = res?.data?.map(name => ({
  //         value: name._id,
  //         label: name.name
  //       }));
  //       setCityData(options1);
  //     }).catch((error) => {
  //       console.error('Error fetching province data:', error);
  //       toast.error('Error fetching province data');
  //     });
  //   }
  // };

  // const handleChange5 = (selectedOption4) => {
  //   setSelectedOption5(selectedOption4);
  //   props?.setCurrentPage(1);
  //   setGoTOPage(1);
  // };

  const handleReset = () => {
    props?.setCurrentPage(1);
    props?.setSearch("");
    setSelectedAddress("Province");
    setAutocompleteSearch('');
    setAddress({
      location: '',
      city: '',
      state: '',
      country: '',
      postalCode: '',
    });
    setSelectedCategory('');
    setIsOnlyCategory(1);
    setRadius(0);
    setIsHeadOffice(0);
  //   if (autocompleteRef.current) {
  //     autocompleteRef.current = null;
  // }
    if (dropdownRef.current) {
      const dropdown = new window.bootstrap.Dropdown(dropdownRef.current);
      dropdown.hide();
  }
  if(inputRef.current.value) {
    inputRef.current.value = '';
  }
    window.history.replaceState(
      {
        ...window.history.state,
        selectedCategory: '',
        autocompleteSearch: '',
        selectedAddress: '',
        radius: 0,
        isOnlyCategory: 1,
        isHeadOffice: 0,
      },
      ''
    );
    // api()
  };




  useEffect(() => {
    localStorage.setItem('bookmarks', JSON.stringify(checkBookMark));
  }, [checkBookMark]);

  const firmFilter = () => {
    axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/filterd`, {
      headers: {
        Authorization: `Bearer ${logindata?.token}`,
      },
    })
      .then((res) => {
        const options = res?.data?.data;
        setCategoryOptions(options);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    const debounceTime = setTimeout(() => {
      firmFilter();
    }, 1000);
    return () => clearTimeout(debounceTime);
  }, [])

  const fetchData = async () => {
    window.scrollTo(0, 0);
    if (token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/logged-user-data`, {
          params: {
            id: localUserId,
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response?.data?.message === "Unauthozied User") {
          localStorage.removeItem("login");
          navigate("/");
          toast.error("Unauthozied user !");
        }
        const bookmarkId = response?.data?.bookmark_details?.map(firm => firm.Firm_id);
        const mainBookmarkId = response?.data?.bookmark_details;
        setbookmarkLength(response?.data?.bookmark_details?.length);
        setbookmarkData(bookmarkId);
        setUserAddress(response?.data?.address)
        setMainBookmarkId(mainBookmarkId);
        setProfileImage(response?.data?.profile_picture);
      } catch (error) {
        if (error?.message === "Request failed with status code 401") {
          localStorage.removeItem('login');
          navigate("/");
          toast.error(`Unauthozied user !`);
        }
      }
    } else {
      toast.error('Please login again !')
    }

  };





  // useEffect(() => {
  //   console.log("call 3")
  //   fetchData();
  // }, [checkBookMark]);

  const deleteFatchData = async (id) => {
    const filteredBookmarkDetails = mainBookmarkId?.filter(detail => detail.Firm_id === id)
    const filteredBookmarkDetailsIds = filteredBookmarkDetails?.map(bookmark => bookmark._id);
    try {
      const response = await axios.delete(
        `${process.env.REACT_APP_PCMA_BACKEND}/api/bookmark-remove/${bookMarkuserId}`,
        {
          params: {
            "bookmark_id": filteredBookmarkDetailsIds[0]
          },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response?.data?.message === "Unauthozied User") {
        localStorage.removeItem("login");
        navigate("/");
        // toast.error("Unauthozied user !");
      }
      if (response?.data?.message === "Bookmark Removed.") {
        api();
        setTimeout(() => {
          toast.error("Bookmark removed");
        }, 1000);
      }
      // fetchData();

    } catch (error) {
      if (error?.message === "Request failed with status code 401") {
        localStorage.removeItem('login');
        navigate("/");
        // toast.error(`Unauthozied user !`);
      }
    }
  }

  const fetchNoticData = async () => {
    if (token) {
      try {
        const response = await axios.get(`${process.env.REACT_APP_PCMA_BACKEND}/api/all-notice-data-user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        if (response?.data?.message === "Unauthozied User") {
          localStorage.removeItem("login");
          navigate("/");
          // toast.error("Unauthozied user !");
        }
        setNoticeData(response?.data?.notice_data?.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error?.message === "Request failed with status code 401") {
          localStorage.removeItem('login');
          navigate("/");
          // toast.error(`Unauthozied user !`);
        }
      }
    }
  };

  useEffect(() => {
    fetchNoticData()
  }, [])

  const handleClick = (data) => {
    navigate(`/firm/firm-details/${data.userId}`);
  };

  const handleButtonClick = (phone) => {
    navigator.clipboard.writeText(phone);
    toast.success(`Phone number copied to clipboard ${phone}`)
  };

  const handleNoticeModal = (data) => {
    const noticeDate = formatNoticeData(data?.updated_at);
    setNotificationDate(noticeDate)
    setShowModal(true);
    setNotificationName(data?.name);
    setNotificationmessage(data?.message)
  };

  const handleKeyDown = (event) => {
    if (event.key === ' ' || event.key === 'Spacebar') {
      event.preventDefault();
    }
  };


  const handleBack = () => {
    if (props?.currentPage == 1) {
      navigate('/')
    } else {
      props?.setCurrentPage(1);
    }

  };

  const blockInvalidChar = e => ['e', 'E', '+', '-']?.includes(e.key) && e.preventDefault();


  // if (!isAutocompleteLoaded) return <div>Loading Maps</div>;
  if (!isAutocompleteLoaded) return <Row style={{ height: "100vh" }}>
    <Col
      lg={12}
      md={12}
      sm={12}
      className="d-flex justify-content-center align-content-center"

      style={{ height: "100vh" }}
    >
      <BallTriangle
        height={100}
        width={100}
        radius={5}
        color="#0f66b4"
        ariaLabel="ball-triangle-loading"
        wrapperClassclassName={{}}
        wrapperStyle=""
        visible={true}
      />
    </Col>
  </Row>;


  return (
    <>

      <>
        <div className="slider_img_div_box" >
          <Container fluid className="">
            <Row className="g-2" style={{ paddingBottom: "10px" }}>
              <Col className="col-2  order-1  d-flex align-items-center justify-content-start py-3">
                <Link to="/">
                  <img
                    src="./asset/logo.png"
                    className="header_img_logo"
                    alt=""
                  />
                </Link>
              </Col>
              <Col className="col-12 col-md-6 col-xl-5  order-3 order-md-2 d-flex align-items-center justify-content-start">
                <div
                  className="search_box d-flex align-items-center  justify-content-start"
                  style={{
                    border: "2px solid rgba(159, 194, 225, 0.80)",
                    borderRadius: "6px",
                    width: "100%",
                    padding: '8px',
                    backgroundColor: "#FFFFFF",
                  }}

                >
                  <div className="dropdown search_dropdown_menu">
                    <a className="btn  dropdown-toggle" style={{ backgroundColor: 'rgba(15, 102, 180, 0.10)', color: '#0F66B4' }} href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                      Registered Firms
                    </a>

                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <li><Link className="dropdown-item" to="/registered-individual-search"  onClick={handleReset}>Registered Individuals</Link></li>
                      {/* <li><Link className="dropdown-item" to="/members-search"> Members </Link></li> */}
                    </ul>
                  </div>


                  <form className="d-flex w-100">
                    <input
                      type="search"
                      className="top-search"
                      style={{ width: "100%" }}
                      placeholder="Search By Region,Registered Firms ..."
                      value={searchTerm}

                      onChange={(e) => {
                        let inputValue = e.target.value;
                        const sanitizedValue = inputValue.replace(/^\s+/g, '').replace(/\s{2,}/g, ' ');
                        handleSearch(sanitizedValue);
                      }}
                      // onChange={(event) => handleSearch(event.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          if (searchTerm.length > 0) {
                            api(true);
                            e.target.blur();
                          }
                        }
                      }}

                      onBlur={(e) => {
                        e.target.disabled = false;
                      }}
                      onFocus={(e) => {
                        e.target.disabled = false;
                      }}
                    />
                    <button type="button" className="reset-search-button me-2" onClick={handleResetSearch}><i className="fa-solid fa-arrow-rotate-right me-2"></i></button>
                    <button
                      type="button"
                      className="top-search-button"
                      style={{ cursor: 'auto' }}
                    >
                      <i className="fa-solid fa-magnifying-glass"></i>
                    </button>
                  </form>
                </div>
              </Col>
              <Col className=" col-10  col-md-4 col-xl-5   order-2 order-md-3 d-flex align-items-center justify-content-end">
                <div className="icons-top-bar icons_top_bar d-flex align-items-center justify-content-around"></div>
                <div className="dropdown align-items-center mt-3">
                  <div
                    data={noticeDatas?.length}
                    className="notice_svg me-3"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      style={{ cursor: "pointer" }}
                      viewBox="0 0 30 30"
                      fill="none"
                    >
                      <path
                        d="M23.4375 12.1375V11.2563C23.4375 6.42 19.6575 2.5 15 2.5C10.3425 2.5 6.5625 6.42 6.5625 11.2563V12.1375C6.56401 13.1894 6.26381 14.2198 5.6975 15.1062L4.3125 17.2625C3.04875 19.2313 4.01375 21.9075 6.2125 22.53C11.9576 24.1587 18.0424 24.1587 23.7875 22.53C25.9863 21.9075 26.9513 19.2313 25.6875 17.2638L24.3025 15.1075C23.7358 14.2211 23.4351 13.1908 23.4363 12.1388L23.4375 12.1375Z"
                        fill="#A7ADB4"
                      />
                      <path
                        d="M9.375 22.75C10.1937 24.935 12.4025 27.5 15 27.5C17.5975 27.5 19.8062 24.935 20.625 22.75"
                        fill="#A7ADB4"
                      />
                    </svg>
                  </div>
                  <ul
                    className="dropdown-menu notice_data py-0"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li className="p-3 border-bottom">
                      <p className="mb-0 d-flex align-items-center justify-content-between">
                        Notifications
                        <span>
                          <i className="fa-solid fa-bars-staggered"></i>
                        </span>
                      </p>
                    </li>

                    <li>
                      <ul className=" list-unstyled p-0 notice_data_item_box">
                        {noticeDatas?.map((noticeData, index) => {
                          return (
                            <li key={index}>
                              <Link
                                className="dropdown-item"
                                onClick={() => {
                                  handleNoticeModal(noticeData);
                                }}
                              >
                                <p className="notice_data_item mb-0">
                                  {noticeData?.name}
                                </p>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </li>
                  </ul>
                </div>

                {/* <div className="d-flex flex-row top-bar-dashboard">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      className="p-0 ms-2 drop_down_dasboard d-flex justify-content-between align-items-center mt-2"
                      style={{
                        backgroundColor: "transparent",
                        border: "transparent",
                      }}
                    >
                      <div className="data-user text-dark d-flex justify-content-center flex-column me-3">
                        <h5 className="text-end mb-0">
                          {capitalizeFirstLetter(userName)}
                        </h5>
                        <h6 className="mb-0 text-end">{userAddress}</h6>
                      </div>

                      {profileImage?.length > 0 ? (
                        <span
                          className='j_header_profile_img'
                          style={{
                            border: "1px solid black",
                            borderRadius: "50%",
                            transition: '0.3s'
                          }}
                        >
                          <img
                            className="fluid"
                            src={`${profileImage}`}
                            alt="logo "
                            style={{
                              borderRadius: "8px",
                              height: "3rem",
                              width: "3rem",
                              borderRadius: "100px",
                            }}
                          />{" "}
                        </span>
                      ) : (
                        <span
                          className='j_header_profile_img'
                          style={{
                            border: "1px solid black",
                            borderRadius: "50%",
                            transition: '0.3s'
                          }}
                        >
                          <img
                            src="./asset/users.png"
                            className="fluid"
                            alt=""
                            style={{
                              borderRadius: "8px",
                              height: "3rem",
                              width: "3rem",
                              borderRadius: "100px",
                            }}
                          />
                        </span>
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        className="d-flex justify-content-between align-items-center"
                        href="/bookmarks"
                      >
                        Bookmark{" "}
                        <span>
                          <i
                            style={{ fontSize: '14px', color: "#898b8d" }}
                            className="fa-solid fa-bookmark"
                          ></i>
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item
                        className="d-flex justify-content-between align-items-center"
                        href="/settings"
                      >
                        Manage Profile {" "}
                        <span>
                          <i
                            style={{ fontSize: '14px', color: "#898b8d" }}
                            className="fa-solid fa-gear"
                          ></i>
                        </span>
                      </Dropdown.Item>
                      <Dropdown.Item href="/">
                        {" "}
                        <button
                          className="logout w-100"
                          onClick={() => logout()}
                        >
                          Logout
                        </button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div> */}

                <div className="d-flex flex-row top-bar-dashboard">
                  <div className="dropdown">
                    <button
                      className="btn dropdown-toggle p-0 ms-2 drop_down_dasboard d-flex justify-content-between align-items-center mt-2"
                      type="button"
                      id="dropdownMenuButton"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      style={{
                        backgroundColor: "transparent",
                        border: "transparent",
                      }}
                    >
                      <div className="data-user text-dark d-flex justify-content-center flex-column me-3">
                        <h5 className="text-end mb-0">
                          {capitalizeFirstLetter(userName)}
                        </h5>
                        <h6 className="mb-0 text-end">{userAddress}</h6>
                      </div>
                      {profileImage?.length > 0 ? (
                        <span
                          className="j_header_profile_img"
                          style={{
                            border: "1px solid black",
                            borderRadius: "50%",
                            transition: "0.3s"
                          }}
                        >
                          <img
                            className="fluid"
                            src={`${profileImage}`}
                            alt="Profile"
                            style={{
                              borderRadius: "8px",
                              height: "3rem",
                              width: "3rem",
                              borderRadius: "100px",
                            }}
                          />
                        </span>
                      ) : (
                        <span
                          className="j_header_profile_img"
                          style={{
                            border: "1px solid black",
                            borderRadius: "50%",
                            transition: "0.3s"
                          }}
                        >
                          <img
                            src="./asset/users.png"
                            className="fluid"
                            alt="Default Profile"
                            style={{
                              borderRadius: "8px",
                              height: "3rem",
                              width: "3rem",
                              borderRadius: "100px",
                            }}
                          />
                        </span>
                      )}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                      <li>
                        <a
                          className="dropdown-item d-flex justify-content-between align-items-center"
                          href="/bookmarks"
                        >
                          Bookmark
                          <span>
                            <i
                              style={{ fontSize: '14px', color: '#898b8d' }}
                              className="fa-solid fa-bookmark"
                            ></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <a
                          className="dropdown-item d-flex justify-content-between align-items-center"
                          href="/settings"
                        >
                          Manage Profile
                          <span>
                            <i
                              style={{ fontSize: '14px', color: '#898b8d' }}
                              className="fa-solid fa-gear"
                            ></i>
                          </span>
                        </a>
                      </li>
                      <li>
                        <span className="dropdown-item" onClick={() => logout()}>
                          <button className="logout w-100">
                            Logout
                          </button>
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>

              </Col>
            </Row>
            <div className="bg-white shadow-sm mt-3 areaBox filer_opt_holds_main" style={{ borderRadius: '10px' }}>
              <Row >
                <div className="col-lg-9">
                  {/* <div className="firm-filer_opt_holds d-flex align-items-start  justify-content-start justify-content-md-start flex-wrap" > */}





                  {/* <div className=" me-2  d-flex h-100 pt-3 align-items-center  filter_div_txt">
                      <p className="me-2 mb-0 "> <svg xmlns="http://www.w3.org/2000/svg" style={{ color: '#0f66b4' }} width="30" height="30" fill="currentColor" className="bi bi-funnel" viewBox="0 0 16 16">
                        <path d="M1.5 1.5A.5.5 0 0 1 2 1h12a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.128.334L10 8.692V13.5a.5.5 0 0 1-.342.474l-3 1A.5.5 0 0 1 6 14.5V8.692L1.628 3.834A.5.5 0 0 1 1.5 3.5zm1 .5v1.308l4.372 4.858A.5.5 0 0 1 7 8.5v5.306l2-.666V8.5a.5.5 0 0 1 .128-.334L13.5 3.308V2z" />
                      </svg></p>
                      <p className="mb-0">
                        filter <br /> <span>by</span>
                      </p>
                    </div> */}


                  {/* <div className={` filter_div ${selectedOption2?.value ? 'active' : ''}`}>
                      <label htmlFor="">
                        Country
                      </label>
                      <Select
                        value={selectedOption2}
                        onChange={handleChange2}
                        options={countryData}
                        styles={colourStyles}
                        onKeyDown={handleKeyDown}
                      />
                    </div>

                    <div className={` filter_div ${selectedOption1?.value ? 'active' : ''}`}>
                      <label htmlFor="" className="pointer-cursor">Province</label>
                      <Select
                        value={selectedOption1}
                        onChange={handleChange1}
                        options={provinceData}
                        className="pointer-cursor"
                        styles={colourStyles}
                        onKeyDown={handleKeyDown}
                        isDisabled={selectedOption2?.value?.length > 0 ? false : true}
                      />
                    </div>

                    <div className={` filter_div ${selectedOption?.value ? 'active' : ''}`}>
                      <label htmlFor="">
                        City
                      </label>
                      <Select
                        value={selectedOption}
                        onChange={handleChange}
                        options={
                          cityData
                        }
                        onMenuScrollToBottom={handleMenuScroll}
                        onKeyDown={handleKeyDown}
                        styles={colourStyles}
                        isDisabled={selectedOption1?.value?.length > 0 ? false : true}
                      // placeholder="Dealing Representative"
                      />
                    </div> */}
                  {/* Hasan start from here */}




                  {/* <div className="firm-filter-opt">
                      <p>Category</p>
                      <div>
                        <div className="dropdown cat-dropdown">
                          <button
                            className="btn btn-secondary dropdown-toggle"
                            type="button"
                            data-bs-toggle="dropdown"
                            data-bs-auto-close="false"
                            aria-expanded="false"
                          >
                            {selectedCategory}
                          </button>
                          <div className="dropdown-menu cat-dropdown-list">
                            <div className="cat-dropdown-list-header">
                              <div className="cat-dropdown-menu">
                                <label>
                                  <input
                                    type="radio"
                                    name="category"
                                    value={1}
                                    checked={isOnlyCategory === 1}
                                    onChange={handleCategoryChange}
                                  />
                                  Registered in single category
                                </label>
                              </div>
                              <div className="cat-dropdown-menu">
                                <label>
                                  <input
                                    type="radio"
                                    name="category"
                                    value={0}
                                    checked={isOnlyCategory === 0}
                                    onChange={handleCategoryChange}
                                  />
                                  Registered in multiple categories
                                </label>
                              </div>
                            </div>
                            <ul className="cat-dropdown-list-show">
                              {categoryOptions.length > 0 ? (
                                categoryOptions.map((option, index) => (
                                  <li key={index} onClick={() => handleSelectOption(option.category)}>
                                    <Link>{option.category}</Link>
                                  </li>
                                ))
                              ) : (
                                <li>
                                  <p className="mb-0">No Data</p>
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  <Row >
                    <div className="col-xxl-5 col-lg-6">
                      <div className="firm-filer_opt">
                        <p>Address</p>
                        <div className="search_box d-flex align-items-center  justify-content-start"
                          style={{
                            borderRadius: "6px",
                            width: "100%",
                            padding: '7px',
                            backgroundColor: "#FFFFFF",
                          }}>
                          <div className="dropdown search_dropdown_menu">
                            <a className="btn  dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                              {selectedAddress}
                            </a>

                            <div className="dropdown-menu select-address">

                              <ul className="" >
                                {dropdownOptions?.map((dropdownOptions, index) => (
                                  <li key={index} style={{ display: selectedAddress === dropdownOptions ? "none" : "block" }}>
                                    <button
                                      className=""
                                      onClick={() => handleAddressSelect(dropdownOptions)}>
                                      {dropdownOptions}
                                    </button>
                                  </li>
                                ))}

                              </ul>
                            </div>
                          </div>
                          <div className="d-flex w-100">
                            <Autocomplete
                              onLoad={(autocomplete) => (autocompleteRef.current = autocomplete)}
                              // value={autocompleteSearch}
                              onPlaceChanged={onPlaceChanged}
                              className="w-100"
                            >
                              <input
                                type="search"
                                className="top-search w-100"
                                placeholder={`Search ${selectedAddress}`}
                                ref={inputRef}
                                onChange={()=>{
                                  setEmptyState(false)
                                  if(inputRef.current.value?.length === 0){
                                    setEmptyState(true)
                                  }
                                }}
                                // value={autocompleteSearch}
                                // onChange={handleSearchAddressChange}
                                // onKeyPress={(e) => {
                                //   if (e.key === 'Enter') {
                                //     e.preventDefault();
                                //     if (autocompleteSearch?.length > 0) {
                                //       api();
                                //       e.target.blur();
                                //     }
                                //   }
                                // }}


                              />
                            </Autocomplete>

                          </div>
                        </div>
                      </div>
                    </div>
                       <div className={`col-xxl-4 ${selectedAddress === 'Near Location' ? 'col-lg-3' : 'col-lg-6'} mt-lg-0 mt-3`}>
                      <div className="firm-filer_opt">
                        <p>Category</p>
                        <div className={` ${selectedCategory ? 'active' : ''}`}>
                          <div class="dropdown cat_dropdown">
                            <button className={` btn btn-secondary dropdown-toggle ${selectedCategory?.length > 0 ? 'active' : ''}`} type="button" data-bs-toggle="dropdown" ds-auto-close="outside"   aria-expanded="false" ref={dropdownRef} >
                              <span> {selectedCategory?.length > 0 ? selectedCategory : 'Category'}</span>
                            </button>
                            <div className="dropdown-menu cat_dropdown_list">
                              <div className="cat_dropdown_list_header">
                                <div className="cat_dropdown_menu"> <label><input type="radio" value={1} checked={isOnlyCategory === 1} onChange={handleCategoryChange} />Registered in only category</label></div>
                                <div className="cat_dropdown_menu"> <label><input type="radio" value={0} checked={isOnlyCategory === 0} onChange={handleCategoryChange} /> Registered with other category</label></div>
                              </div>
                              <ul className="cat_dropdown_list_show">
                                {categoryOptions?.length > 0 ? (
                                  categoryOptions.map((option, index) => (
                                    // <li key={index} onClick={() => handleSelectOption(option?.category)}>
                                    //   <Link>{option?.category}</Link>
                                    // </li>
                                    <li key={index}>
                                      <span
                                        className=""
                                        // style={{ cursor: 'pointer' }}
                                        style={{
                                          cursor: 'pointer',
                                          fontWeight: selectedCategory === option?.category ? 'bold' : 'normal',
                                          color: selectedCategory === option?.category ? '#0f66b4' : 'black',
                                        }}
                                        onClick={() => handleSelectOption(option?.category)}
                                      >
                                        {option?.category}
                                      </span>
                                    </li>
                                  ))
                                ) : (
                                  <li>
                                    <p className="mb-0 text-center">No Data</p>
                                  </li>
                                )}

                              </ul>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                    {selectedAddress === 'Near Location' ? <div className="col-xxl-3 col-lg-3 ">
                      {selectedAddress === 'Near Location' ?
                        <div className="firm-filer_opt mt-lg-0 mt-1" style={{ paddingLeft: '8px' }}>
                          <div>
                            <p className="mb-0">Distance Range</p> <span className="responsive-font1" style={{fontSize:"12px", color:"rgb(133 131 131)"}}>Note: Adjust Range according to the requirement</span>
                            <div style={{ position: 'relative' }} className="pt-4 mt-1 range-box">
                              <Slider
                                className="custom-distance-slider"
                                min={50}
                                max={1000}
                                step={50}
                                marks={{}}
                                // defaultValue={500}
                                value={radius}
                                onChange={handleDistanceChange}
                                // disabled={autocompleteSearch?.length > 0 ? false : true}
                                trackStyle={{ backgroundColor: '#0F66B4', height: '10px' }}
                                railStyle={{ backgroundColor: '#ddd', height: '10px' }}
                                handleStyle={{
                                  borderColor: '#0F66B4',
                                  borderWidth: '3px',
                                  height: '20px',
                                  width: '20px',
                                }}
                                dotStyle={{
                                  height: '10px',
                                  width: '10px',
                                  backgroundColor: '#0F66B4',
                                  borderRadius: '50%',
                                  top: '0%',
                                }}
                                activeDotStyle={{
                                  height: '10px',
                                  width: '10px',
                                  backgroundColor: '#0F66B4',
                                  borderRadius: '50%',
                                  top: '0%',
                                }}
                              />
                              <div
                                style={{
                                  position: 'absolute',
                                  top: '-5px',
                                  left: `${((radius - 50) / 950) * 100}%`,
                                  transform: 'translateX(-50%)',
                                  backgroundColor: '#0F66B4',
                                  color: '#fff',
                                  padding: '5px 12px',
                                  borderRadius: '20px',
                                  whiteSpace: 'nowrap',
                                  fontSize: '12px'
                                }}
                              >
                                {radius} KM
                              </div>
                            </div>
                          </div>
                        </div> : ''}
                    </div> : ""}




                  </Row>
                </div>
                <div className="col-lg-3  d-flex align-items-center justify-content-xl-end justify-content-center mt-xl-0 mb-xl-0" style={{
                  marginBottom: selectedAddress === 'Near Location' ? '5px' : undefined,
                  marginTop: selectedAddress !== 'Near Location' ? '5px' : undefined
                }}
                >
                  <div>

                    <button
                      className={`reset-filter py-4 ${selectedAddress === 'Near Location' ? 'mt-3' : 'mt-4'}`}
                      onClick={handleReset}
                      style={{
                        color: '#FF4740',
                        backgroundColor: '#FFF0EB',
                        whiteSpace: "nowrap",
                        cursor: disable ? "not-allowed" : "pointer",
                      }}
                      disabled={disable}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M8 16C10.0444 16 11.8259 15.3222 13.3444 13.9667C14.863 12.6111 15.7333 10.9185 15.9556 8.88889H14.1333C13.9259 10.4296 13.2407 11.7037 12.0778 12.7111C10.9148 13.7185 9.55556 14.2222 8 14.2222C6.26667 14.2222 4.7963 13.6185 3.58889 12.4111C2.38148 11.2037 1.77778 9.73333 1.77778 8C1.77778 6.26667 2.38148 4.7963 3.58889 3.58889C4.7963 2.38148 6.26667 1.77778 8 1.77778C9.02222 1.77778 9.97778 2.01481 10.8667 2.48889C11.7556 2.96296 12.5037 3.61481 13.1111 4.44444H10.6667V6.22222H16V0.888889H14.2222V2.97778C13.4667 2.02963 12.5444 1.2963 11.4556 0.777778C10.3667 0.259259 9.21482 0 8 0C6.88889 0 5.84815 0.211111 4.87778 0.633333C3.90741 1.05556 3.06296 1.62593 2.34444 2.34444C1.62593 3.06296 1.05556 3.90741 0.633333 4.87778C0.211111 5.84815 0 6.88889 0 8C0 9.11111 0.211111 10.1519 0.633333 11.1222C1.05556 12.0926 1.62593 12.937 2.34444 13.6556C3.06296 14.3741 3.90741 14.9444 4.87778 15.3667C5.84815 15.7889 6.88889 16 8 16Z" fill="#FF4740" />
                      </svg>

                      Reset
                    </button>
                  </div>
                  <div>

                    {d && (
                      <div className="" style={{ paddingLeft: "10px" }}>
                        {noData?.length == 0 && isDownloading == false ? (
                          <button
                            // disabled={disableDownloadCsv}
                            onClick={() => { downloadData() }}
                            className={`export-data py-4 ${selectedAddress === 'Near Location' ? 'mt-3' : 'mt-4'}`}
                            style={{
                              color: '#fff',
                              backgroundColor: '#0F66B4',
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <path d="M9 0L9 8.15L11.6 5.55L13 7L8 12L3 7L4.4 5.55L7 8.15V0H9ZM14 16C14.55 16 15.0208 15.8042 15.4125 15.4125C15.8042 15.0208 16 14.55 16 14V11H14V14L2 14V11H0V14C0 14.55 0.195833 15.0208 0.5875 15.4125C0.979166 15.8042 1.45 16 2 16L14 16Z" fill="#D9EDFF" />
                            </svg>
                            Export
                          </button>
                        ) : (
                          <button
                            disabled={isDownloading}
                            // onClick={() => downloadData()}
                            className={`export-data py-4 ${selectedAddress === 'Near Location' ? 'mt-3' : 'mt-4'}`}
                            style={{
                              color: '#fff',
                              backgroundColor: '#0F66B4',
                              opacity: isDownloading ? 0.7 : 0.6,
                              cursor: isDownloading ? 'not-allowed' : 'pointer',
                            }} > {" "}
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                              <path d="M9 0L9 8.15L11.6 5.55L13 7L8 12L3 7L4.4 5.55L7 8.15V0H9ZM14 16C14.55 16 15.0208 15.8042 15.4125 15.4125C15.8042 15.0208 16 14.55 16 14V11H14V14L2 14V11H0V14C0 14.55 0.195833 15.0208 0.5875 15.4125C0.979166 15.8042 1.45 16 2 16L14 16Z" fill="#D9EDFF" />
                            </svg>{" "}
                            {isDownloading ? "Loading" : 'Export'}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </Row>
            </div>

            {isLoading ? (
              <Container fluid className="loader">
                <Row
                  // className="g-2 pb-3 d-flex justify-content-center align-content-center "
                  style={{ height: "79vh" }}
                >
                  <Col
                    lg={12}
                    md={12}
                    sm={12}
                    className="d-flex justify-content-center align-content-center"
                  >
                    <BallTriangle
                      height={100}
                      width={100}
                      radius={5}
                      color="#0f66b4"
                      ariaLabel="ball-triangle-loading"
                      wrapperClassclassName={{}}
                      wrapperStyle=""
                      visible={true}
                    />
                  </Col>
                </Row>
              </Container>
            ) : (
              <>

                <Row>
                  {noData?.length > 0 ? ("") : (<Col xl={12} lg={12} md={12} sm={12} className="mt-4">
                    <div className="account-information  d-flex align-items-center px-4 justify-content-between mb-3">

                      <span className="p-1"> Total Registered Firms : {totalData}</span>
                    </div>
                  </Col>
                  )}
                </Row>
                <span onClick={handleBack} className="d-inline-block mb-3 text-decoration-none" style={{ color: '#0F67B6', fontSize: '18px', fontWeight: '600', fontFamily: `'lato',sans-serif`, cursor: 'pointer' }}><i className="fa-solid fa-arrow-left-long me-2"></i> Go Back</span>
                <Firm firms={firms} longitudes={longitudes} latitudes={latitudes} setIsLoading={setIsLoading} token={token} localUserId={localUserId} noData={noData} />

                <Row className="py-4">

                  <Col xxl={8}
                    lg={8}
                    md={12}
                    sm={12} className="d-flex flex-wrap flex-sm-nowrap justify-content-center justify-content-sm-between py-2" style={{ gap: '10px' }}>
                    {/* <Col lg={6} className="">
                      <div className="pagination_section">
                        {noData?.length > 0 ? (
                          ""
                        ) :
                          <ResponsivePagination
                          maxWidth={7}
                            total={totalPages}
                            current={props?.currentPage}
                            onPageChange={(page) => handlePageChange(page)}
                          />
                        }
                      </div></Col> */}

                    <Col lg={6} className="">
                      <div className="pagination_section d-flex">
                        {noData?.length > 0 ? (
                          ""
                        ) :
                          <CustomPagination
                            // maxWidth={5}
                            total={totalPages}
                            current={props?.currentPage}
                            onPageChange={(page) => handlePageChange(page)}
                          />
                        }
                      </div></Col>
                    <Col lg={6} className="d-flex justify-content-end">
                      {noData?.length > 0 ? (
                        ""
                      ) :
                        <div>
                          <ul className=" list-unstyled new_side_pagination ">
                            <li style={{ whiteSpace: 'nowrap' }}>Go to page</li>
                            <li>
                              <label htmlFor="pageNumber">
                                <input
                                  type="number"
                                  name=""
                                  id="pageNumber"
                                  placeholder={onfocas ? '' : goToPage}
                                  value={goToPage ? goToPage : " "}
                                  onFocus={() => placeholderDataHide(true)}
                                  onBlur={() => { placeholderDataHide(false); setGotopag(true) }}
                                  onChange={(e) => {
                                    if (e.target.value > totalPages) {
                                      toast.error(`Page ${e.target.value} does not exist`);
                                      setGoTOPage("")
                                    } else {
                                      setNewtGoTOPage(e.target.value);
                                      setGoTOPage(e.target.value)
                                    }
                                  }}
                                  onKeyDown={blockInvalidChar}
                                />
                              </label>
                            </li>
                            <li>
                              <div onClick={() => { handlePageSearch(newtGoTOPage) }}>
                                <i className="fa-solid fa-arrow-right"></i>
                              </div>

                            </li>
                          </ul>
                        </div>
                      }
                    </Col>
                  </Col>
                </Row>
              </>)}
          </Container>
        </div>
        <Footer />
      </>

      <div className="d-flex justify-content-center align-items-center">
        <Modal show={showModal} onHide={handleClose} centered size="lg" id="notificaion-modal">
          <Modal.Header closeButton>
            <Modal.Title>
              <span style={{ color: "#0F66B4" }}>Notifications</span>{" "}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="model-content">
            <div className="notification-img">
              <div
                className="img_container "

              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 39 39"
                  fill="none"
                  className=""
                >
                  <path
                    d="M14.6091 26.6064L19.5 23.6384L24.3909 26.6064L23.095 21.0467L27.4425 17.2844L21.7155 16.8246L19.5 11.5575L17.2844 16.8246L11.5575 17.2844L15.905 21.0467L14.6091 26.6064ZM19.5 38.3947L13.8984 32.8768H6.12316V25.1015L0.605225 19.5L6.12316 13.8984V6.12316H13.8984L19.5 0.605225L25.1015 6.12316H32.8768V13.8984L38.3947 19.5L32.8768 25.1015V32.8768H25.1015L19.5 38.3947ZM19.5 33.7128L23.6802 29.5326H29.5326V23.6802L33.7128 19.5L29.5326 15.3197V9.46736H23.6802L19.5 5.28711L15.3197 9.46736H9.46736V15.3197L5.28711 19.5L9.46736 23.6802V29.5326H15.3197L19.5 33.7128Z"
                    fill="#0F66B4"
                  />
                </svg>
              </div>
              <span>{notificationDate}</span>
            </div>

            <div className="modal_body">
              <h4>{notificationName}</h4>
              <div className="notification_message ">
                <p>
                  {notificationmessage}
                </p>
              </div>
            </div>
          </Modal.Body>

        </Modal>

      </div>
    </>
  );
};

export default User;
